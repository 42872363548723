import { createBrowserHistory } from 'history'
import QueryString from 'qs'

export const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
})

export function qs(queryString, parameter) {
  const strToMap = queryString
    .slice(1)
    .split('&')
    .reduce((acc, item) => {
      const [name, ...value] = item.split('=')
      return {
        ...acc,
        [name]: value.join('='),
      }
    }, {})
  return strToMap[parameter] === undefined ? '' : strToMap[parameter]
}

export const pushPreservePath = (newPath) => {
  let path = newPath
  if (newPath.includes('?')) {
    const search = QueryString.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })
    const searchNew = QueryString.parse(newPath.split('?')[1])
    path = `${newPath.split('?')[0]}?${QueryString.stringify({
      ...search,
      ...searchNew,
    })}`
  } else {
    path = `${newPath}${window.location.search}`
  }
  history.push(path)
}
