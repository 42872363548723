export default function mergeObjects(obj1, obj2) {
    const mergedObject = {};
  
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        mergedObject[key] = obj1[key];
      }
    }
  
    for (const key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (mergedObject[key]) {
          mergedObject[key] = Object.assign({}, mergedObject[key], obj2[key]);
        } else {
          mergedObject[key] = obj2[key];
        }
      }
    }
  
    return mergedObject;
  }
  
  export function removeEmptyStrings(obj) {
    const cleanedObj = {};
  
    for (const key in obj) {
      const value = obj[key];
  
      if (value === null || value === "" || value === undefined) {
        continue;
      }
  
      if (Array.isArray(value)) {
        cleanedObj[key] = value.filter(v => v !== null && v !== "" && v !== undefined);
      } else if (typeof value === "object") {
        cleanedObj[key] = removeEmptyStrings(value);
      } else {
        cleanedObj[key] = value;
      }
    }
  
    return cleanedObj;
  }