import mergeObjects from './../../../utils/helper'
/* eslint-disable default-param-last */
export const RESET_FORM_DATA = 'form//RESET_FORM_DATA';
export const SAVE_FORM_DATA = 'form//SAVE_FORM_DATA';
export const ADD_CONTACT_DATA = 'form/ADD_CONTACT_DATA';


export function saveFormData(data) {
  return {
    type: SAVE_FORM_DATA,
    payload: data,
  };
}

export function addContactData(data) {
  return {
    type: ADD_CONTACT_DATA,
    payload: data,
  };
}

export function resetFormData() {
  return {
    type: RESET_FORM_DATA,
  };
}

export function getFormData(store) {
  return store.form.data;
}

const initialState = {
  data: {},
};

export default function (state = initialState, action) {
  const newState = mergeObjects(state.data, action.payload)
  switch (action.type) {
  case RESET_FORM_DATA:
    return {
      ...state,
      data: {},
    };

  case SAVE_FORM_DATA:
    return {
      ...state,
      data: newState,
    };

  case ADD_CONTACT_DATA:
    return {
      ...state,
      data: {
        ...state.data,
        DACcustomerData: {
          ...state.data.DACcustomerData,
          ...action.payload,
        },
      },
    };

  default:
    return state;
  }
}
