import { createStore, combineReducers } from 'redux';

import formReducer from './features/form.redux';
import dataModelsReducer from './features/data-models.redux';
import storefrontReducer from './features/storefront.redux';
import dataStorageReducer from './features/data-storage.redux';

const reducer = combineReducers({
  form: formReducer,
  dataModel: dataModelsReducer,
  storefront: storefrontReducer,
  dataStorage: dataStorageReducer,
});

const store = createStore(
  reducer,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

export { store };
