export const BRAND_NAME = process.env.REACT_APP_WEBSITE_BRAND
export const CONNECTION_TIMEOUT = 30000
export const POLLING_INTERVAL = 10000
export const MAX_POLLING_CYCLES = 60
export const MEDIA_STORAGE_EXPIRATION_DAYS = 30
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_SEPARATOR = DATE_FORMAT.split('').find(
  (item) => !item.match(/^[a-zA-Z]*$/)
)
export const NUMBER_FORMAT = 'es'
export const CURRENCY = 'EUR'
export const CURRENCY_SYMBOL = '€'

export const ANALYTICS_DEFAULTS = {
  viewChange: '',
  pagename: 'Application Form',
  platform: 'operatelease',
  country: 'ES',
  language: 'es',
  brand: BRAND_NAME,
}

export const FILE_UPLOAD_STATUS = {
  SUCCESS: 'SUCCESS',
  LOADING: 'LOADING',
  UNSUPPORTED: 'UNSUPPORTED',
  TOO_BIG: 'TOO_BIG',
  ERROR: 'ERROR',
}

export const ERRORS = {
  TIMEOUT: 'timeout',
  POLLING_ERROR: 'polling error',
  GENERAL: 'general error',
}

export const MAX_FILESIZE = 1000 * 1000 * 4

export const getAnalyticsBrand = (brand) => {
  if (!brand || !brand.length) {
    return ANALYTICS_DEFAULTS.brand
  }
  return brand.toLowerCase() === 'volkswagen' ? 'vw' : brand.toLowerCase()
}

export const ADOBE_API_KEY = '78b4dbe9da3e1b14c8455cf76ca11b77e26311cb'

const CSBR_BRANDS = ['vwfs']

// some aspects of the visual presentation are different for CSbR
export const CSBR_MODE_ENABLED = CSBR_BRANDS.includes(BRAND_NAME)
