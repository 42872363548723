import { countryCodes } from "./resources/base/models"

export const transformDate = (date) => {
  if (!date) return undefined
  const day = new Date(date).getDate()
  const month = new Date(date).getMonth() + 1
  const year = new Date(date).getFullYear()
  const hour = new Date(date).getHours()
  const minutes = new Date(date).getMinutes()

  const correctDay = addLeadingZero(day)
  const correctMonth = addLeadingZero(month)

  return `${correctDay}.${correctMonth}.${year} ${hour}:${minutes}`
}

export const getValidityDate = () => {
  const date = new Date()
  date.setDate(date.getDate() + 30)
  return transformDate(date)
}

const addLeadingZero = (number) => (number <= 9 ? `0${number}` : number)

export const getPrivacyPolicy = () => {
  return `${process.env.PUBLIC_URL}/assets/base/documents/INFORMACION_DETALLADA_DE_PRIVACIDAD_202205.pdf`
}

export const mapCountryCode = (code) => {
  for (let i = 0; i < countryCodes.length; i++){
    if (countryCodes[i].label.includes(code)){
      return countryCodes[i].value
    }
  }
  return '+34';
}
