/**
 * The base resource needs to be declared in this file and not imported,
 * because it must resolve to a string directly, otheriwse it will not work in
 * the getModels method.
 */
const BASE_RESOURCE = 'base';

export const getBrandName = () => {
  if (
    typeof window.reactConfig === 'object'
    && window.reactConfig.brand !== '%brand%'
  ) {
    return window.reactConfig.brand;
  }
  return process.env.REACT_APP_WEBSITE_BRAND;
};

export const getBronsonBrand = (brand) => {
  switch (brand) {
  case 'vwfs':
    return 'bluelabel';
  case 'vw6':
    return 'vw6';
  case 'vwcv':
    return 'vw6';
  case 'vwn':
    return 'vw6';
  default:
    return brand;
  }
};

export const getPages = (brand) => import(`../../resources/${brand}/pages/index.json`).catch(() => import(`../../resources/${BASE_RESOURCE}/pages/index.json`));

export const getModels = (brand) => import(`../../resources/${brand}/models`).catch(() => import(`../../resources/${BASE_RESOURCE}/models`));

export const getLogoSrc = async (brandName) => {
  let logoDefault;
  let logoSmall;
  const logoBrand = getBronsonBrand(brandName);

  switch (logoBrand) {
  case 'skoda':
    logoDefault = await import(
        `@vwfs-bronson/bronson-${logoBrand}/dist/img/logo.png`
    );
    logoSmall = await import(
        `@vwfs-bronson/bronson-${logoBrand}/dist/img/logo-small-screen.png`
    );

    return {
      logoDefault: logoDefault.default,
      logoSmall: logoSmall.default,
    };
  case 'vw6':
  case 'bluelabel':
  case 'seat':
  case 'audi':
    logoDefault = await import(
        `@vwfs-bronson/bronson-${logoBrand}/dist/img/logo.svg`
    );

    return {
      logoDefault: logoDefault.default,
      logoSmall: logoDefault.default,
    };
  case 'vwcv':
  default:
    logoDefault = await import(
      '@vwfs-bronson/bronson-bluelabel/dist/img/logo.svg'
    );

    return {
      logoDefault: logoDefault.default,
      logoSmall: logoDefault.default,
    };
  }
};

export function useStaticHeader(brandName = getBrandName()) {
  return ['vwfs'].includes(brandName);
}
