/* eslint-disable default-param-last */
/* eslint-disable react/destructuring-assignment */

export const FETCH_DATASTORAGE_PENDING = 'dataStorage//FETCH_DATASTORAGE_PENDING';
export const FETCH_DATASTORAGE_DONE = 'dataStorage//FETCH_DATASTORAGE_DONE';
export const FETCH_DATASTORAGE_ERROR = 'dataStorage//FETCH_DATASTORAGE_ERROR';
export const SET_DAC_ERROR = 'dataStorage//SET_DAC_ERROR';
export const SET_DAC_CREDIT_DECISION = 'dataStorage//SET_DAC_CREDIT_DECISION';

export function pending() {
  return {
    type: FETCH_DATASTORAGE_PENDING,
    payload: {},
  };
}

export function done(data) {
  return {
    type: FETCH_DATASTORAGE_DONE,
    payload: {
      data,
    },
  };
}

export function error(err) {
  return {
    type: FETCH_DATASTORAGE_ERROR,
    payload: {
      err,
    },
  };
}

export function setDacError(dacErr) {
  return {
    type: SET_DAC_ERROR,
    payload: {
      dacErr,
    },
  };
}

export function setDacCreditDecision(decision) {
  return {
    type: SET_DAC_CREDIT_DECISION,
    payload: {
      decision,
    },
  };
}

export function getDataStorage(store) {
  return store.dataStorage.data;
}

export function getDacResponse(store) {
  return store.dataStorage.dacResponse;
}

const initialState = {
  pending: false,
  data: {},
  error: null,
  dacResponse: {
    error: null,
    creditResponse: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
  case FETCH_DATASTORAGE_PENDING:
    return {
      ...state,
      data: {},
      pending: true,
      error: null,
    };

  case FETCH_DATASTORAGE_DONE:
    return {
      ...state,
      data: action.payload.data,
      pending: false,
      error: null,
    };
  case FETCH_DATASTORAGE_ERROR:
    return {
      ...state,
      data: {},
      pending: false,
      error: true,
    };

  case SET_DAC_ERROR:
    return {
      ...state,
      dacResponse: {
        ...state.dacResponse,
        error: action.payload.dacErr,
      },
    };

  case SET_DAC_CREDIT_DECISION:
    return {
      ...state,
      dacResponse: {
        ...state.dacResponse,
        decision: action.payload.decision,
      },
    };

  default:
    return state;
  }
}
