/* eslint-disable  */
import { get } from 'lodash'
import { Analytics } from './index'
import { getBrandName } from '../brand'
import { BRAND_NAME } from '../../config'

const mapBrand = (brand) => {
  const _brand = `${brand}`.toLowerCase()
  switch (_brand) {
    case 'volkswagen':
      return 'vw'
    default:
      return _brand;
  }
}
export function pushStorefrontDataToDatalayer(storefrontData) {

  Analytics.setJourneyCommon(
    {
      product: [{
        attributes: {
          recurringPayment: get(storefrontData, 'financialProduct.calculation.subsequentInstallment.amount'), duration: get(storefrontData, 'financialProduct.calculation.duration'),
          durationUnit: get(storefrontData, 'financialProduct.calculation.durationUnit'),
          yearlyMileage: get(storefrontData, 'financialProduct.calculation.yearlyMileage'),
          mileageUnit: get(storefrontData, 'financialProduct.calculation.mileageUnit'),
          downPaymentAmount: get(storefrontData, 'calculation.downPaymentAmount'),
        },
        vehicleModel: [{
          manufacturer: get(storefrontData, 'vehicleData.model.manufacturer'),
          name: get(storefrontData, 'vehicleData.model.name'),
          descriptionLong: get(storefrontData, 'vehicleData.model.descriptionLong') || get(storefrontData, 'vehicleData.model.description'),
          currentMileage: get(storefrontData, 'vehicleData.currentMileage'),
          currentMileageUnit: 'KILOMETERS',
          colorExterior: get(storefrontData, 'vehicleData.model.colorExterior')
        }]
      }],
      dealerData: {
        companyId: get(storefrontData, 'dealerData.companyId'),
        companyName: get(storefrontData, 'dealerData.companyName'),
        egionId: null,
        KVPS: get(storefrontData, 'dealerData.companyId'),
        address: {
          street: get(storefrontData, 'dealerData.address.street'),
          zipCode: get(storefrontData, 'dealerData.address.zipCode'),
          city: get(storefrontData, 'dealerData.address.city'),
          state: null,
        },
      },
      core: {
        attributes: {
          brand: mapBrand(getBrandName()) || BRAND_NAME || 'vwfs'
        }
      }
    }
  )
}
