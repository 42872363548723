/* eslint-disable default-param-last */
export const SET_DATA_MODELS = 'dataModel//SET_DATA_MODELS';

export function setDataModels(data) {
  return {
    type: SET_DATA_MODELS,
    payload: data,
  };
}

export function getDataModels(store) {
  return store.dataModel.data;
}

const initialState = {
  data: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
  case SET_DATA_MODELS:
    return {
      ...state,
      data: action.payload,
    };

  default:
    return state;
  }
}
