export const industryList = [
  { value: 'Actividades Inmobiliarias', label: 'Actividades Inmobiliarias' },
  { value: 'Actividades Sanitarias', label: 'Actividades Sanitarias' },
  { value: 'Actividades Veterinarias', label: 'Actividades Veterinarias' },
  { value: 'Administracion Publica', label: 'Administracion Publica' },
  { value: 'Agricultura', label: 'Agricultura' },
  { value: 'Agua, Gas y Electricidad', label: 'Agua, Gas y Electricidad' },
  {
    value: 'Comercio, Ventas y Reparaciones',
    label: 'Comercio, Ventas y Reparaciones',
  },
  { value: 'Construccion', label: 'Construccion' },
  { value: 'Educacion', label: 'Educacion' },
  { value: 'Entidades de Seguro', label: 'Entidades de Seguro' },
  {
    value: 'Entidades Financieras y de Seguros',
    label: 'Entidades Financieras y de Seguros',
  },
  {
    value: 'Fabricacion de Material de Transporte',
    label: 'Fabricacion de Material de Transporte',
  },
  {
    value: 'Ganaderia, Caza y Silvicultura',
    label: 'Ganaderia, Caza y Silvicultura',
  },
  { value: 'Hosteleria', label: 'Hosteleria' },
  {
    value: 'Industria Alimentacion, Bebidas y Tabaco',
    label: 'Industria Alimentacion, Bebidas y Tabaco',
  },
  {
    value: 'Industria Construccion Maquinaria',
    label: 'Industria Construccion Maquinaria',
  },
  {
    value: 'Industria de La Madera y del Corcho',
    label: 'Industria de La Madera y del Corcho',
  },
  {
    value: 'Industria del Cuero y del Calzado',
    label: 'Industria del Cuero y del Calzado',
  },
  {
    value: 'Industria del Papel y Artes Graficas',
    label: 'Industria del Papel y Artes Graficas',
  },
  {
    value: 'Industria del Plastico y del Caucho',
    label: 'Industria del Plastico y del Caucho',
  },
  {
    value: 'Industria del Vidrio y Mat. Construccion',
    label: 'Industria del Vidrio y Mat. Construccion',
  },
  {
    value: 'Industria Material Eletrico y Optico',
    label: 'Industria Material Eletrico y Optico',
  },
  { value: 'Industria Metalurgica', label: 'Industria Metalurgica' },
  { value: 'Industria Quimica', label: 'Industria Quimica' },
  {
    value: 'Industria Textil y de la Confeccion',
    label: 'Industria Textil y de la Confeccion',
  },
  {
    value: 'Industrias Manufactureras Diversas',
    label: 'Industrias Manufactureras Diversas',
  },
  { value: 'Mineria', label: 'Mineria' },
  {
    value: 'Otros Servicios Empresariales',
    label: 'Otros Servicios Empresariales',
  },
  { value: 'Pesca', label: 'Pesca' },
  { value: 'Refinerias', label: 'Refinerias' },
  {
    value: 'Servicios Asesoramiento y Gestion',
    label: 'Servicios Asesoramiento y Gestion',
  },
  { value: 'Servicios Informaticos', label: 'Servicios Informaticos' },
  {
    value: 'Transporte, Correos y Comunicaciones',
    label: 'Transporte, Correos y Comunicaciones',
  },
  {
    value: 'Actividades No Renumeradas',
    label: 'Actividades No Renumeradas',
  },
];
