export const maritalStatusList = [
  { value: 'Soltera/o', label: 'Soltera/o' },
  { value: 'Casada/o', label: 'Casada/o' },
  { value: 'Viuda/o', label: 'Viuda/o' },
  { value: 'Divorciada/o', label: 'Divorciada/o' },
  { value: 'Separada/o', label: 'Separada/o' },
];

export const MARITAL_STATUS_TRANSLATION_ENUM = Object.freeze({
  SINGLE: maritalStatusList[0].value,
  MARRIED: maritalStatusList[1].value,
  WIDOWED: maritalStatusList[2].value,
  DIVORCED: maritalStatusList[3].value,
  SEPARATED: maritalStatusList[4].value,
});
