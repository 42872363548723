export const occupationGroupList = [
  { value: 'A.T.S.', label: 'A.T.S.' },
  { value: 'ADMINISTRATIVO', label: 'ADMINISTRATIVO' },
  { value: 'AGENTES', label: 'AGENTES' },
  { value: 'AGRICULTOR', label: 'AGRICULTOR' },
  { value: 'ALBAÑIL', label: 'ALBAÑIL' },
  { value: 'AMA DE CASA', label: 'AMA DE CASA' },
  { value: 'ARTESANO', label: 'ARTESANO' },
  { value: 'ARTISTA', label: 'ARTISTA' },
  { value: 'AUTONOMOS SIN EMPLEADOS', label: 'AUTONOMOS SIN EMPLEADOS' },
  { value: 'AUTONOMOS HASTA 5 EMPLEADOS', label: 'AUTONOMOS HASTA 5 EMPLEADOS' },
  { value: 'AUTONOMOS CON MAS DE 5 EMPLEADOS', label: 'AUTONOMOS CON MAS DE 5 EMPLEADOS' },
  { value: 'AZAFATA', label: 'AZAFATA' },
  { value: 'CANTERO', label: 'CANTERO' },
  { value: 'CARGA Y DESCARGA', label: 'CARGA Y DESCARGA' },
  { value: 'CARPINTERO', label: 'CARPINTERO' },
  { value: 'CERAMISTA', label: 'CERAMISTA' },
  { value: 'CHAPISTA', label: 'CHAPISTA' },
  { value: 'CHOFER', label: 'CHOFER' },
  { value: 'COMERCIANTE', label: 'COMERCIANTE' },
  { value: 'CONSERJE', label: 'CONSERJE' },
  { value: 'DEPENDIENTE', label: 'DEPENDIENTE' },
  { value: 'DEPORTISTA', label: 'DEPORTISTA' },
  { value: 'ELECTRICISTA', label: 'ELECTRICISTA' },
  { value: 'EMPLEADAS DE HOGAR', label: 'EMPLEADAS DE HOGAR' },
  { value: 'ESTUDIANTE', label: 'ESTUDIANTE' },
  { value: 'FONTANERO', label: 'FONTANERO' },
  { value: 'FORJADOR', label: 'FORJADOR' },
  { value: 'FUNCIONARIO CIVIL', label: 'FUNCIONARIO CIVIL' },
  { value: 'GANADERO', label: 'GANADERO' },
  { value: 'HOSTELERO', label: 'HOSTELERO' },
  { value: 'INDUSTRIAL', label: 'INDUSTRIAL' },
  { value: 'INFORMATICO', label: 'INFORMATICO' },
  { value: 'JOYERO', label: 'JOYERO' },
  { value: 'LIMPIEZA', label: 'LIMPIEZA' },
  { value: 'MECANICO', label: 'MECANICO' },
  { value: 'MILITAR', label: 'MILITAR' },
  { value: 'MINERO', label: 'MINERO' },
  { value: 'OBRERO', label: 'OBRERO' },
  { value: 'PELUQUERO', label: 'PELUQUERO' },
  { value: 'PERSONAL DOCENTE', label: 'PERSONAL DOCENTE' },
  { value: 'PESCADOR', label: 'PESCADOR' },
  { value: 'PINTOR', label: 'PINTOR' },
  { value: 'POLICIA', label: 'POLICIA' },
  { value: 'PORTERO', label: 'PORTERO' },
  { value: 'RELIGIOSO', label: 'RELIGIOSO' },
  { value: 'SOLDADOR', label: 'SOLDADOR' },
  { value: 'TAXISTA', label: 'TAXISTA' },
  { value: 'TECNICO', label: 'TECNICO' },
  { value: 'TITULADO MEDIO', label: 'TITULADO MEDIO' },
  { value: 'TITULADO SUPERIOR', label: 'TITULADO SUPERIOR' },
  { value: 'TRANSPORTISTA', label: 'TRANSPORTISTA' },
  { value: 'VENDEDOR', label: 'VENDEDOR' },
  { value: 'VIGILANTE JURADO', label: 'VIGILANTE JURADO' },
];
