export const activityList = [
  { value: '011', label: 'EXPLOTACION EXTENSIVA GANADO BOVINO' },
  { value: '011', label: 'DOCTORES Y LICENCIADOS EN BIOLOGIA' },
  { value: '011', label: 'DIRECTORES DE CINE Y TEATRO' },
  { value: '012', label: 'EXPLOTAC. INTENSI.GANADO BOVINO DE LECHE' },
  { value: '012', label: 'INGENIEROS AGRONOMOS Y DE MONTES' },
  { value: '012', label: 'AYUDANTES DE DIRECCION' },
  { value: '013', label: 'EXPLOT.INTENSIVA GANADO BOVINO DE CEBO' },
  { value: '013', label: 'VETERINARIOS' },
  { value: '013', label: 'ACTORES DE CINE Y TEATRO' },
  { value: '014', label: 'EXTRAS ESPECIALISTAS, DOBLES, COMPARSAS' },
  { value: '015', label: 'OPERADORES CAMARA:CINE,TV.,VIDEO' },
  { value: '016', label: 'HUMORISTAS,CARICATOS,EXCENTRICOS' },
  { value: '017', label: 'APUNTADORES Y REGIDORES' },
  { value: '018', label: 'ARTISTAS CIRCENSES' },
  { value: '019', label: 'ACTIVIDADES CINE,TEATRO, Y CIRCO NCOP' },
  { value: '021', label: 'EXPLOTACION EXTENSIVA DE GANADO OVINO' },
  { value: '021', label: 'TECNICOS BIOLOGIA, AGRONOMIA Y SILVICULT' },
  { value: '021', label: 'DIRECTORES COREOGRAFICOS' },
  { value: '022', label: 'EXPLOT.INTENSIV.GANADO OVINO DE CRIA' },
  { value: '022', label: 'INGENIEROS TEC. AGRICOLAS Y FORESTALES' },
  { value: '022', label: 'BAILARINES' },
  { value: '023', label: 'EXPLOTAC.INTENSIVA GANADO OVINO DE CEBO' },
  { value: '023', label: 'INGENIEROS TECNICOS TOPOGRAFOS' },
  { value: '024', label: 'EXPLOTACION DE GANADO CAPRINO' },
  { value: '024', label: 'AUXILIARES Y AYUDANTES VETERINARIA' },
  { value: '029', label: 'OTRAS ACTIV. DEL BAILE NCOP' },
  { value: '031', label: 'EXPLOTACION EXTENSIVA GANADO PORCINO' },
  { value: '031', label: 'MAESTROS Y DIRECTORES DE MUSICA' },
  { value: '032', label: 'EXPLOTAC.INTENSIVA GANADO PORCINO CRIA' },
  { value: '032', label: 'INTERPRETES DE INSTRUMENTOS MUSICALES' },
  { value: '033', label: 'EXPLOTAC. INTENSIVA GANADO PORCINO CEBO' },
  { value: '033', label: 'CANTANTES' },
  { value: '039', label: 'OTRAS ACTIV. RELAC. MUSICA NCOP' },
  { value: '041', label: 'AVICULTURA DE PUESTA' },
  { value: '041', label: 'JUGADORES Y ENTRENADORES DE FUTBOL' },
  { value: '041.1', label: 'REPRODUCTORAS DE PUESTA' },
  { value: '041.2', label: 'PONEDORAS HUEVOS A PARTIR CUATRO MESES' },
  { value: '042', label: 'AVICULTURA DE CARNE' },
  { value: '042', label: 'JUGADORES,ENTRENADORES, DE TENIS Y GOLF' },
  { value: '042.1', label: 'REPRODUCTORAS DE CARNE' },
  { value: '042.2', label: 'POLLOS Y PATOS PARA CARNE' },
  { value: '042.3', label: 'PAVOS, FAISANES Y PALMIPEDAS_REPRODUCTOR' },
  { value: '042.4', label: 'PAVOS, FAISANES Y PALMIPEDAS PARA CARNE' },
  { value: '042.5', label: 'CODORNICES PARA CARNE' },
  { value: '043', label: 'PILOTOS,ENTRENADORES MOTO Y AUTOCICLISMO' },
  { value: '044', label: 'BOXEADORES,ENTRENADORES DE BOXEO' },
  { value: '045', label: 'JUGADORES,ENTRENADORES DE BALONCESTO' },
  { value: '046', label: 'CORREDORES,ENTRENADORES DE CICLISMO' },
  { value: '047', label: 'BALONMANO,VOLEIBOL,PELOTA,HIPICA,LUCHA' },
  { value: '048', label: 'ARBITROS DE ESPECTACULOS DEPORTIVOS' },
  { value: '049', label: 'OTRAS ACTIV. RELACIONADAS DEPORTE NCOP' },
  { value: '051', label: 'CUNICULTURA' },
  { value: '051', label: 'MATADORES DE TOROS' },
  { value: '052', label: 'REJONEADORES' },
  { value: '053', label: 'SUBALTERNOS' },
  { value: '054', label: 'JEFES DE CUADRILLAS COMICAS Y SIMILARES' },
  { value: '055', label: 'OTRO PERSONAL CUADRILLAS COMICAS Y SIMIL' },
  { value: '059', label: 'OTRAS ACTIV. ESPEC.TAURINOS NCOP' },
  { value: '061', label: 'EXPLOT.GANADO CABALLAR,MULAR Y ASNAL' },
  { value: '062', label: 'APICULTURA' },
  { value: '069', label: 'OTRAS EXPLOTACIONES GANADERAS' },
  { value: '071', label: 'EXPLOTACIONES MIXTAS' },
  { value: '099', label: 'OTRO PROFESIONALES AGRICULTURA... NCOP' },
  { value: '111', label: 'EXTR. PREP. Y AGLOMERACION DE HULLA' },
  { value: '111', label: 'DRES.Y LICENCIADOS FISICAS Y GEOLOGICAS' },
  { value: '111.1', label: 'EXTRACCION Y PREPARACION DE HULLA' },
  { value: '111.2', label: 'EXTRACC. Y PREP. HULLA SUBBITUMINOSA' },
  { value: '111.3', label: 'PREPARAC. HULLA FACTORIA INDEPENDIENTE' },
  { value: '111.4', label: 'AGLOMERACION DE HULLA' },
  { value: '112', label: 'EXTRACCION Y PREPARACION DE ANTRACITA' },
  { value: '112', label: 'INGENIEROS DE MINAS' },
  { value: '112.1', label: 'EXTRACC. Y PREPARACION DE ANTRACITA' },
  { value: '112.2', label: 'PREPARACION ANTRACITA FACTORIA INDEPEND.' },
  { value: '113', label: 'EXTRACCION, PREPARACION DE LIGNITO PARDO' },
  { value: '113.1', label: 'EXTRACC. Y PREP. LIGNITO PARDO' },
  { value: '113.2', label: 'PREP. LIGNITO PARDO EN FACTORIA INDEPEN.' },
  { value: '114', label: 'FABRICACION DE COQUE' },
  { value: '121', label: 'PROSP. PETROLEO Y GAS NAT. Y TRAB. AUX.' },
  { value: '121', label: 'DRES. Y LICENCIADOS EN QUIMICAS' },
  { value: '121.1', label: 'TRABAJOS DE TESTIFICACION EN SONDEOS' },
  { value: '121.2', label: 'DESVIACION SONDEOS Y CIMENTACION POZOS' },
  { value: '121.3', label: 'TOMAS MEDIDA PRESION FONDO DE POZOS' },
  { value: '121.9', label: 'OTRAS ACTIV. DE PROSPECCION' },
  { value: '122', label: 'EXTRAC. Y DISTRIB. DE CRUDOS DE PETROLEO' },
  { value: '122.1', label: 'EXTRACCION DE CRUDOS DE PETROLEO' },
  { value: '122.2', label: 'DISTRIBUCION DE CRUDOS DE PETROLEO' },
  { value: '123', label: 'EXTRAC. DEPUR. Y DISTRIB. DE GAS NATURAL' },
  { value: '123.1', label: 'EXTRACCION Y DEPURACION DE GAS NATURAL' },
  { value: '123.2', label: 'DISTRIBUCION DE GAS NATURAL' },
  { value: '124', label: 'EXTRACCION DE PIZARRAS BITUMINOSAS' },
  { value: '130', label: 'REFINO DE PETROLEO' },
  { value: '131', label: 'INGENIEROS TEC. MINAS FACULT. Y PERITOS' },
  { value: '141', label: 'EXTRAC.Y PREP. DE MINERALES RADIACTIVOS' },
  { value: '142', label: 'PREP.MINERALES RADIACTIVOS FACT.INDEPEN.' },
  { value: '143', label: 'TRANSF. MINE. RADIACT. TTO. Y ALMA. RES.' },
  { value: '143.1', label: 'TRANSFORMACION DE MINERALES RADIOACTIVOS' },
  { value: '143.2', label: 'TRATAMIENTO DE RESIDUOS RADIOACTIVOS' },
  { value: '143.3', label: 'ALMACENAMIENTO DE RESIDUOS RADIOACTIVOS' },
  { value: '151', label: 'PRODUC. TRANS. Y DIST. DE ENER. ELEC.' },
  { value: '151.1', label: 'PRODUCCION DE ENERGIA HIDROELECTRICA' },
  { value: '151.2', label: 'PRODUCCION ENERGIA TERMOELECTRICA' },
  { value: '151.3', label: 'PRODUCCION ENERGIA ELECTRONUCLEAR' },
  { value: '151.4', label: 'OTRAS PRODUCCIONES DE ENERGIA' },
  { value: '151.5', label: 'TTE. Y DISTRIBUCION DE ELECTRICIDAD' },
  { value: '152', label: 'FABRICACION Y DISTRIBUCION DE GAS' },
  { value: '153', label: 'PRODUCC. Y DISTRIB.VAPOR Y AGUA CALIENTE' },
  { value: '161', label: 'CAPT. TRAT.Y DIST. AGUA, NUCLEOS URBANO' },
  { value: '161.1', label: ' CAPTACION,TRATAMIENTO Y DISTRIB. DE AGUA' },
  { value: '161.2', label: ' CAPTACION DE AGUA PARA SU SUMINISTRO' },
  { value: '161.3', label: ' TRATAMIENTO DEL AGUA PARA SU SUMINISTRO' },
  { value: '161.4', label: ' DISTRIBUCION DE AGUA EN NUCLEOS URBANOS' },
  { value: '162', label: 'FABRICACION DE HIELO PARA LA VENTA' },
  { value: '199', label: 'OTROS PROF. RELAC. ENERGIA,MINERIA,QUIM.' },
  { value: '211', label: 'EXTRAC. PREPAR. DE MINERALES DE HIERRO' },
  { value: '211', label: 'INGENIEROS AERONAUTICOS' },
  { value: '211.1', label: ' EXTRACC. Y PREP. MINERALES FERREOS' },
  { value: '211.2', label: ' PREP.MINERALES FERREOS EN FACT. INDEPEN.' },
  { value: '212', label: 'EXTRAC. PREPAR. DE MIN. MET. NO FERREOS' },
  { value: '212', label: 'INGENIEROS NAVALES' },
  { value: '212.1', label: ' EXTRAC.Y PREP. MINERALES METALICOS' },
  { value: '212.2', label: ' PREP.MINERALES METALICOS EN FACT.INDEP.' },
  { value: '213', label: 'INGENIEROS TELECOMUNICACION' },
  { value: '214', label: 'INGENIEROS ARMAMENTO' },
  { value: '221', label: 'SIDERURGIA INTEGRAL' },
  { value: '221', label: 'INGENIEROS TEC. AERONAUTICOS' },
  { value: '221.1', label: ' PTOS.SIDERURGICOS PRIMARIOS (S.I.)' },
  { value: '221.2', label: ' ACERO BRUTO (S.I.)' },
  { value: '221.3', label: ' SEMIPRODUCTOS (S.I.)' },
  { value: '221.4', label: ' PTOS.LAMINADOS EN CALIENTE (S.I.)' },
  { value: '221.5', label: ' PTOS.LAMINADOS EN FRIO (S.I.)' },
  { value: '221.6', label: ' PTOS.SIDERURGICOS DERIVADOS (S.I.)' },
  { value: '221.7', label: ' OTROS PTOS. Y SUBPRODUCTOS (S.I.)' },
  { value: '222', label: 'SIDERURGIA NO INTEGRAL' },
  { value: '222', label: 'INGENIEROS TEC. TELECOMUNICACIONES' },
  { value: '222.1', label: ' ACERO BRUTO (S.N.I.)' },
  { value: '222.2', label: ' SEMIPRODUCTOS (S.N.I.)' },
  { value: '222.3', label: ' LAMINADOS EN CALIENTE (S.N.I.)' },
  { value: '222.4', label: ' LAMINADOS EN FRIO (S.N.I.)' },
  { value: '222.5', label: ' PTOS.DERIVADOS (S.N.I.)' },
  { value: '222.6', label: ' OTROS PTOS. Y SUBPRODUCTOS (S.N.I.)' },
  { value: '223', label: 'FABRICACION DE TUBOS DE ACERO' },
  { value: '223', label: 'AYUDANTES INGENIEROS Y TECNICOS ICAI' },
  { value: '223.1', label: ' PTOS. TUBULARES DE ACERO SIN SOLDADURA' },
  { value: '223.2', label: ' PTOS. TUBULARES DE ACERO SOLDADOS' },
  { value: '223.3', label: ' PTOS. TUBULARES SOLDADOS HELICOIDALMENTE' },
  { value: '223.4', label: ' ACCESORIOS PARA TUBERIAS' },
  { value: '224', label: 'TREFILADO,ESTIRADO Y LAMINADO DE ACERO' },
  { value: '224', label: 'DIBUJANTES TECNICOS' },
  { value: '224.1', label: ' ALAMBRE DE ACERO' },
  { value: '224.2', label: ' PTOS. CALIBRADOS DE ESTIRADO' },
  { value: '224.3', label: 'PTOS. CALIBRADOS POR TORNEADO' },
  { value: '224.4', label: 'PTOS. CALIBRADOS POR RECTIFICADO' },
  { value: '224.5', label: 'PERFILES CONFORMADOS EN FRIO' },
  { value: '224.6', label: 'FLEJE LAMINADO EN FRIO' },
  { value: '224.7', label: 'FLEJE MAGNETICO LAMINADO EN FRIO' },
  { value: '224.8', label: 'FLEJE RECUBIERTO' },
  { value: '225', label: 'PROD. Y PRIMERA TRANSF. DE MET. NO FERR.' },
  { value: '225', label: 'TECNICOS EN TELECOMUNICACION' },
  { value: '225.1', label: 'PROD. Y PRIM. TRANSFORMACION ALUMINIO' },
  { value: '225.2', label: 'PROD. Y PRIM. TRANSFORMACION DE COBRE' },
  { value: '225.9', label: 'PROD. Y PRIM. TRANSF. OTROS METALES' },
  { value: '226', label: 'TECNICOS EN SONIDO' },
  { value: '227', label: 'TECNICOS EN ILUMINACION' },
  { value: '228', label: 'INGENIEROS TECN. NAVALES,AYTES Y PERITOS' },
  { value: '231', label: 'EXTRACCION DE MATERIALES DE CONSTRUCCION' },
  { value: '231.1', label: 'EXTRACCION SUSTANCIAS ARCILLOSAS' },
  { value: '231.2', label: 'EXTRACC. ROCAS Y PIZARRAS CONSTRUCCION' },
  { value: '231.3', label: 'EXTRACC. ARENAS Y GRAVAS CONSTRUCCION' },
  { value: '231.4', label: 'EXTRACCION DE YESO' },
  { value: '231.9', label: 'EXTRACCION OTROS MATERIALES CONSTRUCCION' },
  { value: '232', label: 'EXTRAC. DE SAL POTAS.FOSFATOS Y NITRATOS' },
  { value: '232.1', label: 'EXTRACCION SALES POTASICAS' },
  { value: '232.2', label: 'EXTRACCION FOSFATOS Y NITRATOS' },
  { value: '233', label: 'EXTRACCION DE SAL COMUN' },
  { value: '233.1', label: 'EXTRACCION DE SAL MARINA' },
  { value: '233.2', label: 'EXTRACC. SAL MANANTIAL Y GEMA' },
  { value: '234', label: 'EXTRACCION DE PIRITAS Y AZUFRE' },
  { value: '234.1', label: 'PIRITAS DE HIERRO SIN TOSTAR' },
  { value: '234.2', label: 'AZUFRE NATURAL' },
  { value: '239', label: 'EXTR. OTROS MINER. NO MET. NI ENER.,TUR.' },
  { value: '239.1', label: 'EXTRACCION DE FLUORITA' },
  { value: '239.2', label: 'EXTRACCION DE TURBA' },
  { value: '239.9', label: 'EXTRACC. DE OTROS MINERALES NCOP' },
  { value: '241', label: 'FAB.PTOS.TIERRAS COCIDAS CONSTRUCCION' },
  { value: '241.1', label: 'LADRILLOS BLOQUES Y FORJADOS' },
  { value: '241.2', label: 'TEJAS, BALDOSAS Y OTROS MATERIALES' },
  { value: '242', label: 'FABRICACION DE CEMENTOS CALES Y YESOS' },
  { value: '242.1', label: 'FAB. CEMENTOS ARTIFICIALES' },
  { value: '242.2', label: 'FAB. CEMENTOS NATURALES' },
  { value: '242.3', label: 'FAB. CALES Y YESOS' },
  { value: '243', label: 'FAB. MAT. EN HORMIGON,CEMENTO,YESO,ETC.' },
  { value: '243.1', label: 'FAB. HORMIGONES PREPARADOS' },
  { value: '243.2', label: 'FAB. PTOS. EN FIBROCEMENTO' },
  { value: '243.3', label: 'FAB. OTROS ARTICULOS DERIVADOS CEMENTO' },
  { value: '243.4', label: 'FAB. PAVIMENTOS DERIVADOS DEL CEMENTO' },
  { value: '243.5', label: 'FAB. ARTICULOS DERIVADOS YESO Y ESCAYOLA' },
  { value: '244', label: 'INDUSTRIAS DE LA PIEDRA NATURAL' },
  { value: '244.1', label: 'PIEDRA NATURAL TRITURADA Y CLASIFICADA' },
  { value: '244.2', label: 'PIEDRA NATURAL TALLADA Y ASERRADA' },
  { value: '244.3', label: 'PIEDRA ELABORADA' },
  { value: '245', label: 'FAB. DE ABRASIVOS' },
  { value: '245.1', label: 'MUELAS Y ARTICULOS SIMILARES' },
  { value: '245.2', label: 'OTROS ABRASIVOS' },
  { value: '246', label: 'INDUSTRIA DEL VIDRIO' },
  { value: '246.1', label: 'FAB. VIDRIO PLANO' },
  { value: '246.2', label: 'FAB. VIDRIO HUECO' },
  { value: '246.3', label: 'FAB. VIDRIO TECNICO' },
  { value: '246.4', label: ' FAB. FIBRA DE VIDRIO' },
  { value: '246.5', label: ' MANIPULADO DE VIDRIO' },
  { value: '246.6', label: ' FABRICAC. DE FIBRAS Y ESMALTES CERAMICOS' },
  { value: '247', label: 'FABRICACION DE PRODUCTOS CERAMICOS' },
  { value: '247.1', label: ' FAB. ARTICULOS REFRACTARIOS' },
  { value: '247.2', label: ' FAB. BALDOSAS PARA PAVIMENTOS' },
  { value: '247.3', label: ' FAB. BALDOSAS ESMALTADAS PARA PAVIMENTOS' },
  { value: '247.4', label: ' FAB. VAJILLAS Y ARTICULOS PARA EL HOGAR' },
  { value: '247.5', label: ' FAB. APARATOS SANITARIOS LOZA Y PORCEL.' },
  { value: '247.6', label: ' FAB. AISLADORES EN MATERIAL CERAMICO' },
  { value: '247.9', label: ' FAB. OTROS PTOS. CERAMICOS NCOP' },
  { value: '249', label: 'IND. OTROS PTOS. MINERALES NO MET.' },
  { value: '249.1', label: ' FAB. PTOS. ASFALTICOS' },
  { value: '249.2', label: ' FAB. PTOS. A BASE DE AMIANTO' },
  { value: '249.9', label: ' FAB. PTOS. CON OTROS MINERALES NO METAL' },
  { value: '251', label: 'FAB.PROD.QUIM.BASIC. (EXC.PROD.FARMAC.)' },
  { value: '251.1', label: ' FAB.PTO.QUIMICOS ORGANICOS PETROQUIMICOS' },
  { value: '251.2', label: ' FAB. OTROS PTOS. QUIMICOS ORGANICOS' },
  { value: '251.3', label: ' FAB. PTOS. QUIMICOS INORGANICOS' },
  { value: '251.4', label: ' FAB. PRIMERA MATERIAS PLASTICAS' },
  { value: '251.5', label: ' FAB. CAUCHO Y LATEX SINTETICOS' },
  { value: '251.6', label: ' FAB. FIBRAS ARTIFICIALES Y SINTETICAS' },
  { value: '251.7', label: ' FAB. ACIDO ANHIDRICO FTALICO Y MALEICO' },
  { value: '252', label: 'FAB.PROD.QUIM. DEST. A LA AGRICULTURA' },
  { value: '252.1', label: ' FAB. DE ABONOS' },
  { value: '252.2', label: ' FAB. DE PLAGUICIDAS' },
  { value: '253', label: 'FABRIC. PRODUC.QUIMICOS DEST. INDUSTRIA' },
  { value: '253.1', label: ' FAB. GASES COMPRIMIDOS' },
  { value: '253.2', label: ' FAB. COLORANTES Y PIGMENTOS' },
  { value: '253.3', label: ' FAB. PINTURAS, BARNICES Y LACAS' },
  { value: '253.4', label: ' FAB. DE TINTAS DE IMPRENTA' },
  { value: '253.5', label: ' TRATAMIENTO ACEITES PARA USO INDUSTRIAL' },
  { value: '253.6', label: ' FAB. ACEITES ESENCIALES Y SUST. AROMAT.' },
  { value: '253.7', label: ' FAB. COLAS Y GELATINAS' },
  { value: '253.8', label: ' FAB. EXPLOSIVOS' },
  { value: '253.9', label: ' FAB. OTROS PTOS. QUIMICOS USO INDUSTRIAL' },
  { value: '254', label: 'FABRICACION DE PRODUCTOS FARMACEUTICOS' },
  { value: '254.1', label: ' FAB. PTOS. FARMACEUTICOS DE BASE' },
  { value: '254.2', label: ' FAB. ESPECIALIDADES FARMACEUTICAS' },
  { value: '255', label: 'FAB.OTROS PROD.QUIM. DEST. AL CONS.FINAL' },
  { value: '255.1', label: ' FAB. JABONES COMUNES, DETERGENTES, LEJIA' },
  { value: '255.2', label: ' FAB. JABONES TOCADOR Y PTOS. PERFUMERIA' },
  { value: '255.3', label: ' FAB. DERIVADOS DE CERAS Y PARAFINAS' },
  { value: '255.4', label: ' FAB. MATERIAL FOTOGRAFICO SENSIBLE' },
  { value: '255.5', label: ' FAB. ART. PIROTECNICOS, Y FOSFOROS' },
  { value: '255.9', label: ' FAB. OTROS PTOS. QUIMICOS DE CONSUMO' },
  { value: '299', label: 'OTROS PROF.RELACION AERONAUTICA,COMUNIC.' },
  { value: '311', label: 'FUNDICIONES' },
  { value: '311', label: 'INGENIEROS INDUSTRIALES Y TEXTILES' },
  { value: '311.1', label: ' FUNDICION PIEZAS DE HIERRO Y ACERO' },
  { value: '311.2', label: ' FUNDICION PIEZAS METALES NO FERREOS' },
  { value: '312', label: 'FORJA,ESTAMPADO,EMBUTICION,TROQUELADO' },
  { value: '312.1', label: ' PIEZAS FORJADAS' },
  { value: '312.2', label: ' PIEZAS ESTAMPADAS O TROQUELADAS' },
  { value: '312.3', label: ' PIEZAS EMBUTIDAS, CORTADAS' },
  { value: '313', label: 'TRATAMIENTO Y RECUBRIMIENTO DE METALES' },
  { value: '313.1', label: 'TRATAMIENTO DE METALES' },
  { value: '313.2', label: 'RECUBRIMIENTOS METALICOS' },
  { value: '313.9', label: 'TRAT. PROTECCION DE METALES' },
  { value: '314', label: 'FAB. PRODUCTOS METALICOS EXTRUCTURALES' },
  { value: '314.1', label: 'FAB. ART. CARPINTERIA METALICA' },
  { value: '314.2', label: 'FAB. ESTRUCTURAS METALICAS' },
  { value: '315', label: 'CONSTRUCC. DEPOSITOS Y CALDERERIA' },
  { value: '315.1', label: 'GRANDES CALDERAS' },
  { value: '315.2', label: 'GRANDES DEPOSITOS METALICOS' },
  { value: '316', label: 'FAB.HERR.ACABADAS EN MET.(EXC.MAT.ELEC.)' },
  { value: '316.1', label: 'FAB. HERRAMIENTAS MANUALES' },
  { value: '316.2', label: 'FAB. ARTICULOS DE FERRETERIA/CERRAJERIA' },
  { value: '316.3', label: 'TORNILLERIA Y ART. DERIVADOS ALAMBRE' },
  { value: '316.4', label: 'FAB. ART. MENAJE' },
  { value: '316.5', label: 'FAB. COCINAS, CALENTADORES, CALEFACCION' },
  { value: '316.6', label: 'FAB. MOBILIARIO METALICO' },
  { value: '316.7', label: 'FAB. RECIPIENTES Y ENVASES METALICOS' },
  { value: '316.8', label: 'FAB. ARMAS LIGERAS Y SUS MUNICIONES' },
  { value: '316.9', label: 'OTROS ART. ACABADOS EN METALES NCOP' },
  { value: '319', label: 'TALLERES MECANICOS INDEPENDIENTES' },
  { value: '319.1', label: 'TALLERES DE MECANICA EN GENERAL' },
  { value: '319.9', label: 'TALLERES MECANICOS NCOP' },
  { value: '321', label: 'CONST. MAQ. Y TRACTORES AGRICOLAS' },
  { value: '321', label: 'INGENIEROS TEC. INDUSTRIALES Y TEXTILES' },
  { value: '321.1', label: 'CONSTRUCC. MAQ. AGRICOLAS' },
  { value: '321.2', label: 'CONSTRUCC. TRACTORES AGRICOLAS' },
  { value: '322', label: 'CONST. MAQ. PARA METALES, MADERA, CORCHO' },
  { value: '322', label: 'TECNICOS EN ARTES GRAFICAS' },
  { value: '322.1', label: 'CONS. MAQUINAS PARA TRABAJAR METALES' },
  { value: '322.2', label: 'CONST. MAQUINAS TRABAJAR MADERA/CORCHO' },
  { value: '322.3', label: 'FAB.UTILES PARA MAQUINAS HERRAMIENTAS' },
  { value: '323', label: 'CONST. MAQ. IND. TEX.CUERO,CALZ.,VESTIDO' },
  { value: '323.1', label: 'CONST. MAQUINAS TEXTILES Y ACCESORIOS' },
  { value: '323.2', label: 'CONST. MAQUINAS INDUST. CUERO Y CALZADO' },
  { value: '323.3', label: 'FAB. MAQUINAS DE COSER' },
  { value: '324', label: 'CONST. MAQ. IND. ALIM.QUIM.PLAST.CAUCHO' },
  { value: '324.1', label: 'MAQUINAS IND. ALIMENT.,BEBIDAS Y TAB.' },
  { value: '324.2', label: 'CONST.MAQUINAS PARA INDUSTRIA QUIMICA' },
  { value: '324.3', label: 'CONST.MAQ.IND._DEL CAUCHO Y PLASTICOS' },
  { value: '325', label: 'CONST. MAQ. MIN.CONST.OB.PUB.SIDER.OTROS' },
  { value: '325.1', label: 'CONST.MAQ. MINERIA Y CONSTRUCCION' },
  { value: '325.2', label: 'CONST. MAQUINAS IND. MINERALES NO METAL' },
  { value: '325.3', label: 'CONST. MAQ. PARA SIDERURGIA Y FUNDICION' },
  { value: '325.4', label: 'CONST. MAQ. ELEVACION Y MANIPULACION' },
  { value: '326', label: 'CONST. ORGANOS DE TRANSMISION' },
  { value: '326.1', label: 'FAB.ENGRANAJES,CADENAS DE TRANSMISION' },
  { value: '326.2', label: 'FAB. DE RODAMIENTOS' },
  { value: '329', label: 'CONST. OTRAS MAQUINAS Y EQUIPO MECANICO' },
  { value: '329.1', label: 'CONST. MAQ. IND. PAPEL, CARTON Y AA.GG.' },
  { value: '329.2', label: 'CONST.MAQ. DE LAVADO Y LIMPIEZA EN SECO' },
  { value: '329.3', label: 'CONST. MOTORES Y TURBINAS (EXC.TRANSPOR)' },
  { value: '329.4', label: 'CONST. MAQ. MANIPULACION DE FLUIDOS' },
  { value: '329.9', label: 'CONST. OTRAS MAQ. Y EQUIPOS NCOP' },
  { value: '330', label: 'CONS.MAQ.DE OFICINA Y ORDENADORES' },
  { value: '330.1', label: ' CONS. MAQ. DE OFICINA Y ORDENADORES' },
  { value: '330.2', label: ' INSTALAC. MAQ. OFICINA Y ORDENADORES' },
  { value: '341', label: 'FAB.HILOS Y CABLES ELECTRICOS' },
  { value: '341.1', label: ' FAB. HILOS Y CABLES AISLADOS COMUNICAC.' },
  { value: '341.2', label: ' FAB.HILOS Y CABLES PARA ELECTRICIDAD' },
  { value: '341.3', label: ' FAB. HILOS Y CABLES PARA BOBINAS' },
  { value: '341.4', label: ' FAB.CORDONES FLEXIBLES E HILOS AISLADOS' },
  { value: '341.5', label: ' FAB. HILOS Y CABLES PARA CONSTRUCCION' },
  { value: '341.9', label: ' FAB. OTROS HILOS Y CABLES AISLADOS' },
  { value: '342', label: 'FAB.MATERIAL ELECTRICO DE USO Y EQUIP.' },
  { value: '342.1', label: ' MAQUINAS TRASFORMACION ELECTRICIDAD' },
  { value: '342.2', label: ' OTRO MATERIAL ELECTRICO' },
  { value: '343', label: 'FABRICACION PILAS Y ACUMULADORES' },
  { value: '343.1', label: ' FAB. PILAS ELECTRICAS' },
  { value: '343.2', label: ' FAB. ACUMULADORES ELECTRICOS' },
  { value: '343.3', label: ' FAB. ACCESORIOS DE PILAS Y ACUMULADORES' },
  { value: '344', label: 'FAB. CONTADORES Y APARATOS DE MEDIDA' },
  { value: '345', label: 'FAB. APARATOS ELECTRODOMESTICOS' },
  { value: '345.1', label: ' FAB.COCINAS,HORNOS,PLACAS, Y DEMAS' },
  { value: '345.2', label: ' FAB.REFRIGERADORES Y CONGELADORES' },
  { value: '345.3', label: ' FAB. LAVAVAJILLAS,LAVADORAS Y SECADORAS' },
  { value: '345.4', label: ' FAB.CALENTADORES Y CALEFACC. ELECTRICA' },
  { value: '345.5', label: ' FAB.VENTILADORES Y ACONDICIONADORES' },
  { value: '345.6', label: ' FAB.APARATOS ELEC. AUXILIARES COCINA' },
  { value: '345.7', label: ' FAB.APAR.ELECTRICOS PARA EL HOGAR' },
  { value: '345.8', label: ' FAB. OTROS APARATOS ELECTRICOS' },
  { value: '345.9', label: ' FAB. ACCESORIOS DE APAR. ELECTRODOMEST.' },
  { value: '346', label: 'FAB. LAMPARAS Y MATERIAL DE ALUMBRADO' },
  { value: '346.1', label: ' FAB. LAMPARAS ELECTRICAS' },
  { value: '346.2', label: ' FAB.LUMINARIAS DE ALTA INTENSIDAD' },
  { value: '346.3', label: ' FAB. ART. CARBON Y GRAFITO USO ELECTRIC.' },
  { value: '346.4', label: ' FAB. OTRO MATERIAL DE ALUMBRADO' },
  { value: '346.5', label: ' FAB. ACCESORIOS MATERIAL ALUMBRADO' },
  { value: '351', label: 'FAB. DE APARATOS Y EQUIPO DE TELECOM.' },
  { value: '351.1', label: ' FAB. APARATOS TELEFONICOS Y TELEGRAFICOS' },
  { value: '351.2', label: ' FAB.APARATOS TELECOMUNICACION RADIODIF.' },
  { value: '352', label: 'FAB. APARATOS USO PROFESIONAL CIENTIFICO' },
  { value: '353', label: 'FAB.APARAT.SEÑALIZACION,CONTROL' },
  { value: '354', label: 'FAB.COMPONENTES ELECTRONICOS Y CIRCUITOS' },
  { value: '354.1', label: ' FAB. VALVULAS Y TUBOS ELECTRONICOS' },
  { value: '354.2', label: ' FAB. OTROS COMPONENTES ELECTRONICOS' },
  { value: '354.3', label: ' FAB. COMPONENTES ELECTRONICOS PASIVOS' },
  { value: '354.4', label: ' ACCESORIOS DE COMPONENTES ELECTRONICOS' },
  { value: '355', label: 'FAB. DE APARATOS DE SONIDO E IMAGEN' },
  { value: '355.1', label: ' FAB. RECEPTORES RADIO, TV, Y SONIDO' },
  { value: '355.2', label: ' EDICION SOPORTES AUDIO,VIDEO,INFORMATICA' },
  { value: '361', label: 'CONSTRUCCION DE VEHICULOS AUTOMOVILES' },
  { value: '361.1', label: ' AUTOMOVILES DE TURISMO' },
  { value: '361.2', label: ' AUTOBUSES Y AUTOCARES' },
  { value: '361.3', label: ' CAMIONES' },
  { value: '361.4', label: ' OTROS VEHICULOS AUTOMOVILES' },
  { value: '361.5', label: ' MOTORES PARA VEHICULOS AUTOMOVILES' },
  { value: '361.6', label: ' CHASIS CON MOTOR PARA AUTOMOVILES' },
  { value: '361.7', label: 'COMPONENTES PARA VEHICULOS AUTOMOVILES' },
  { value: '362', label: 'CONSTRUCC. CARROCERIAS, REMOLQUES' },
  { value: '362.1', label: 'CARROCERIAS AUTOMOVILES Y REMOLQUES' },
  { value: '362.2', label: 'REMOLQUES Y VOLQUETES' },
  { value: '363', label: 'FAB.EQUIPO,COMPONENTES,REPUESTOS, COCHES' },
  { value: '363.1', label: 'REPUESTOS Y ACCESORIOS MOTORES AUTOS' },
  { value: '363.2', label: 'ACCESORIOS Y REPUESTOS CARROCERIAS AUTO' },
  { value: '363.9', label: 'OTROS ACCESORIOS Y REPUESTOS AUTOMOVILES' },
  { value: '371', label: 'CONSTRUCCION NAVAL' },
  { value: '371.1', label: 'BUQUES DE CASCO DE ACERO' },
  { value: '371.2', label: 'BUQUES DE CASCO DE MADERA' },
  { value: '371.3', label: 'BUQUES DE CASCO DE PLASTICO' },
  { value: '371.4', label: 'ARTEFACTOS FLOTANTES' },
  { value: '371.5', label: 'MOTORES, Y TURBINAS PARA EMBARCACIONES' },
  { value: '371.6', label: 'ACCESORIOS Y REPUESTOS EMBARCACIONES' },
  { value: '372', label: 'REPARACION Y MANTENIMIENTO DE BUQUES' },
  { value: '372.1', label: 'SERV.REPARACION Y MANTENIM. BUQUES' },
  { value: '372.2', label: 'SERV.DESGUACE DE EMBARCACIONES' },
  { value: '381', label: 'CONST.REPARAC.MANTENIM. FERROCARRILES' },
  { value: '381.1', label: 'MATERIAL FERROVIARIO' },
  { value: '381.2', label: 'SERV. REPARACION MATERIAL FERROVIARIO' },
  { value: '382', label: 'CONSTRUCC.REPARACION, MANT. AERONAVES' },
  { value: '382.1', label: 'AERONAVES E INGENIOS ESPACIALES' },
  { value: '382.2', label: 'SERV. REPARACION Y MANTENIMIENTO AVIONES' },
  { value: '383', label: 'CONSTRUCC. BICICLETAS Y MOTOCICLETAS' },
  { value: '383.1', label: 'MOTOCICLETAS, Y CICLOMOTORES' },
  { value: '383.2', label: 'BICICLETAS, TRICICLOS Y MONOCICLOS' },
  { value: '383.3', label: 'VEHICULOS ESPECIALES CON MEC. PROPULSION' },
  { value: '383.4', label: 'MOTORES PARA MOTOS, CICLIMOTORES' },
  { value: '383.5', label: 'ACCESORIOS PARA MOTOS Y BICICLETAS' },
  { value: '389', label: 'CONSTRUCC. OTRO MATERIAL TRANSPORTE NCOP' },
  { value: '389.1', label: 'REMOLQUES AGRICOLAS' },
  { value: '389.2', label: 'VEHICULOS ACCIONADOS A MANO' },
  { value: '389.9', label: 'ACCESORIOS PARA OTRO MATERIAL TTE. NCOP' },
  { value: '391', label: 'FAB.INSTRUM. PRECISION,MEDIDA,CONTROL' },
  { value: '391.1', label: 'CONTADORES NO ELECTRICOS' },
  { value: '391.2', label: 'INSTRUMENTOS PARA LA NAVEGACION' },
  { value: '391.3', label: 'INSTRUMENTOS TOPOGRAFIA,METEREOLOGIA' },
  { value: '391.4', label: 'INSTRUM. MEDIDA DENSIDAD, TEMPERATURA' },
  { value: '391.5', label: 'INSTRUM. ENSAYOS MECANICOS DE MATERIALES' },
  { value: '391.6', label: 'BALANZAS DE PRECISION' },
  { value: '391.7', label: 'OTROS INSTRUM. PRECISION' },
  { value: '391.8', label: 'ACCESORIOS INSTRUM. APARAT. PRECISION' },
  { value: '392', label: 'FAB. MAT. MEDICO-QUIR. Y APAR. ORTOPED.' },
  { value: '392.1', label: 'FAB. MATERIAL MEDICO-QUIRURGICO' },
  { value: '392.2', label: 'FAB. APARATOS PROTESIS Y ORTOPEDIA' },
  { value: '393', label: 'FAB. INSTRUM. OPTICOS Y MAT. FOTO Y CINE' },
  { value: '393.1', label: 'FAB.INSTRUM.OPTICOS Y MAT. FOTOGRAFICO' },
  { value: '393.2', label: 'FAB. MONTURAS PARA GAFAS NO PLASTICAS' },
  { value: '399', label: 'FAB. RELOJES Y OTROS INSTRUMENTOS NCOP' },
  { value: '399', label: 'OTROS PROFESIONALES INDUSTRIAS MANUFAC.' },
  { value: '399.1', label: 'RELOJES Y CRONOGRAFOS PULSERA Y BOLSILLO' },
  { value: '399.2', label: 'RELOJES DESPERTADORES' },
  { value: '399.3', label: 'RELOJES DE PARED Y SOBREMESA' },
  { value: '399.4', label: ' RELOJES PARA VEHICULOS' },
  { value: '399.5', label: 'RELOJES DE TORRE, ESTACION' },
  { value: '399.6', label: 'APARATOS CON MECANISMOS DE RELOJERIA' },
  { value: '399.7', label: ' MAQUINAS DE RELOJ MONTADAS' },
  { value: '399.8', label: ' CAJAS DE RELOJES' },
  { value: '399.9', label: ' ACCESORIOS DE RELOJES Y OTROS INSTRUM.' },
  { value: '411', label: 'FAB. Y EMVASADO DE ACEITE DE OLIVA' },
  { value: '411', label: 'ARQUITECTOS' },
  { value: '411.1', label: ' FAB. Y ENVASADO DE ACEITE DE OLIVA' },
  { value: '411.2', label: ' FAB. ACEITE DE OLIVA' },
  { value: '411.3', label: ' ENVASADO DE ACEITE DE OLIVA' },
  { value: '412', label: 'FAB. ACEITE Y GRASAS (EXCEPTO OLIVA)' },
  { value: '412', label: 'INGENIEROS CAMINOS, CANALES Y PUERTOS' },
  { value: '412.1', label: ' EXTRACC. Y ENVASADO ACEITES SEMILLAS' },
  { value: '412.2', label: ' OBTENC.Y ENVASADO GRASAS ANIMALES MAR' },
  { value: '412.3', label: ' REFINADO Y OTROS TRATAMIENTOS GRASAS' },
  { value: '412.4', label: ' OBTENCION Y ENVASADO MARGARINA' },
  { value: '413', label: 'SACRIF.PREP.CONSERV. GANADO E INCUB.AVES' },
  { value: '413.1', label: ' SACRIFICIO Y DESPIECE DE GANADO' },
  { value: '413.2', label: ' FAB.PTOS.CARNICOS DE TODAS CLASES' },
  { value: '413.3', label: ' SALAS DESPIECE AUTONOMAS' },
  { value: '413.4', label: ' INCUBACION Y VENTA DE POLLUELOS' },
  { value: '414', label: 'INDUSTRIAS LACTEAS' },
  { value: '414.1', label: ' PREPARACION DE LECHE' },
  { value: '414.2', label: ' PREPARACION LECHE EN CONSERVA' },
  { value: '414.3', label: ' FAB. QUESO Y MANTEQUILLA' },
  { value: '414.4', label: ' ELABORACION DE HELADOS Y SIMILARES' },
  { value: '415', label: 'FAB. JUGOS Y CONSERVAS VEGETALES' },
  { value: '415.1', label: ' CONSERVAS VEGETALES' },
  { value: '415.2', label: ' EXTRACTOS,ZUMOS Y OTROS PREPARADOS' },
  { value: '415.3', label: ' LIMPIEZA,CLASIFIC.Y ENVASE FRUTAS' },
  { value: '416', label: 'FAB. CONSERVAS PESCADO' },
  { value: '416.1', label: ' CONSERVAS DE PESCADO' },
  { value: '416.2', label: ' PTOS.RESIDUALES CONSERVACION PESCADO' },
  { value: '417', label: 'FAB. PRODUCTOS DE MOLINERIA' },
  { value: '417.1', label: ' FAB. HARINAS Y SEMOLAS' },
  { value: '417.2', label: ' FAB. OTROS PTOS. MOLINERIA' },
  { value: '418', label: 'FAB. PASTAS ALIMENT. Y PROD. AMILACEOS' },
  { value: '418.1', label: ' FAB. PASTAS ALIMENTICIAS' },
  { value: '418.2', label: ' FAB. PTOS. AMILACEOS' },
  { value: '419', label: 'INDUST. PAN,BOLLERIA,PASTEL. Y GALLETAS' },
  { value: '419.1', label: ' INDUSTRIA DEL PAN Y BOLLERIA' },
  { value: '419.2', label: ' INDUST. BOLLERIA Y PASTELERIA' },
  { value: '419.3', label: ' INDUST. ELABORACION MASAS FRITAS' },
  { value: '420', label: 'INDUSTRIAS DEL AZUCAR' },
  { value: '420.1', label: ' AZUCAR Y JARABES DE AZUCAR' },
  { value: '420.2', label: ' PTOS. RESIDUALES DE LA INDUSTRIA AZUCAR' },
  { value: '421', label: 'INDUST. CACAO CHOCOL. Y PROD. CONFITERIA' },
  { value: '421', label: 'ARQUITECTOS TECNICOS Y APAREJADORES' },
  { value: '421.1', label: ' INDUSTRIA CACAO Y CHOCOLATE' },
  { value: '421.2', label: ' ELABORACION PTOS. CONFITERIA' },
  { value: '422', label: 'INDUST. PROD.ALIMENT.ANIMAL (I.HAR.PES.)' },
  { value: '422', label: 'INGENIEROS TEC. OBRAS PUBLICAS' },
  { value: '422.1', label: ' FORRAJES DESHIDRATADOS ALIMENTAC. ANIMAL' },
  { value: '422.2', label: ' HARINAS PESCADO Y OTROS PTOS. PIENSOS' },
  { value: '422.3', label: 'ELAB.PIENSOS COMP. CUALQ.CLASE(EXC.4224)' },
  { value: '422.4', label: ' ELAB.PIENSOS COMP. ANIMALES DOMESTICOS' },
  { value: '423', label: 'ELABORACION DE PRODUC. ALIMENT. DIVERSOS' },
  { value: '423.1', label: 'ELAB. CAFE, TE, Y SUCEDANEOS' },
  { value: '423.2', label: 'ELAB. SOPAS PREPARADAS Y CONDIMENTOS' },
  { value: '423.3', label: 'ELAB. PTOS. DIETETICOS Y DE REGIMEN' },
  { value: '423.9', label: 'ELAB. OTROS PTOS. ALIMENTICIOS NCOP' },
  { value: '424', label: 'INDUST. ALCOHOLES ETILICOS DE FERMENT.' },
  { value: '424.1', label: 'DESTILACION Y RECTIFICACION ALCOHOLES' },
  { value: '424.2', label: 'OBTENCION AGUARDIENTES NATURALES' },
  { value: '424.3', label: 'OBTENC. AGUARDIENTES COMPUESTOS' },
  { value: '425', label: 'INDUST. VINICOLAS' },
  { value: '425.1', label: 'ELABORACION Y CRIANZA DE VINOS' },
  { value: '425.2', label: 'ELABORACION DE VINOS ESPUMOSOS' },
  { value: '425.3', label: 'ELABORACION OTROS VINOS ESPECIALES' },
  { value: '425.9', label: 'OTRAS INDUSTRIAS VINICOLAS NCOP' },
  { value: '426', label: 'SIDRERIAS' },
  { value: '426.1', label: 'SIDRA Y OTRAS BEBIDAS FERMENTADAS' },
  { value: '426.2', label: 'PTOS. RESIDUALES DE SIDRERIAS' },
  { value: '427', label: 'FAB. CERVEZA Y MALTA DE CERVEZA' },
  { value: '427.1', label: 'CERVEZA Y MALTA DE CERVEZA' },
  { value: '427.2', label: 'SUBPRODUCTOS RESIDUALES DE CERVEZA' },
  { value: '428', label: 'INDUST. AGUAS MINERALES,GASEOSAS Y OTRAS' },
  { value: '428.1', label: 'PREP. Y ENVASADO DE AGUAS MINERALES' },
  { value: '428.2', label: 'FAB. AGUAS GASEOSAS Y OTRAS BEBIDAS SIN' },
  { value: '429', label: 'INDUST. DEL TABACO' },
  { value: '429.1', label: 'ELAB. CIGARROS, CIGARRILLOS DE TABACO' },
  { value: '429.2', label: 'PRIMERA TRANSFORMACION TABACO' },
  { value: '431', label: 'INDUST. ALGODON Y SUS MEZCLAS' },
  { value: '431', label: 'DELINEANTES' },
  { value: '431.1', label: 'PREP. FIBRAS DE ALGODON' },
  { value: '431.2', label: 'HILADO Y RETORCIDO DEL ALGODON' },
  { value: '431.3', label: 'TEJIDO ALGODON Y SUS MEZCLAS' },
  { value: '432', label: 'INDUST. LANA Y SUS MEZCLAS' },
  { value: '432', label: 'DECORADORES-DISEÑADORES DE INTERIORES' },
  { value: '432.1', label: 'PREP. FIBRAS LANA' },
  { value: '432.2', label: 'HILADO Y RETORCIDO LANA' },
  { value: '432.3', label: 'TEJIDO DE LANA' },
  { value: '433', label: 'INDUSTRIA DE LA SEDA NATURAL' },
  { value: '433.1', label: 'PTOS. IND. SEDA NATURAL Y SUS MEZCLAS' },
  { value: '433.2', label: 'PREP. HILADO Y TEJIDO DE FIBRAS SINTETIC' },
  { value: '434', label: 'INDUSTRIA DE LAS FIBRAS DURAS Y MEZCLAS' },
  { value: '434.1', label: 'FIBRAS DURAS PREPARADAS PARA HILADO' },
  { value: '434.2', label: 'SUBPRODUCTOS DE LA PREP. FIBRAS DURAS' },
  { value: '434.3', label: 'HILADOS Y RETORCIDOS DE FIBRAS DURAS' },
  { value: '434.4', label: 'TEJIDOS DE FIBRAS DURAS Y SUS MEZCLAS' },
  { value: '435', label: 'FAB. GENEROS DE PUNTO' },
  { value: '435.1', label: 'FAB. GENEROS DE PUNTO EN PIEZA' },
  { value: '435.2', label: 'FAB. CALCETERIA' },
  { value: '435.3', label: 'FAB. PRENDAS INTERIORES PUNTO' },
  { value: '435.4', label: 'FAB. PRENDAS EXTERIORES DE PUNTO' },
  { value: '436', label: 'ACABADO DE TEXTILES' },
  { value: '436.1', label: 'TEXTILES BLANQUEADOS' },
  { value: '436.2', label: 'TEXTILES TEåIDOS' },
  { value: '436.3', label: 'TEXTILES ESTAMPADOS' },
  { value: '436.9', label: 'TEXTILES APRESTADOS Y MERCERIZADOS' },
  { value: '437', label: 'FAB. ALFOMBRAS Y TAPIZES Y TEJIDOS IMPR.' },
  { value: '437.1', label: ' FAB. ALFOMBRAS Y TAPICES' },
  { value: '437.2', label: ' FAB. TEJIDOS IMPREGNADOS' },
  { value: '439', label: 'OTRAS INDUSTRIAS TEXTILES' },
  { value: '439.1', label: ' CORDELERIA' },
  { value: '439.2', label: ' FAB. FIELTROS, TULES, ENCAJES, ETC.' },
  { value: '439.3', label: ' FAB. TEXTILES CON FIBRAS RECUPERACION' },
  { value: '439.9', label: ' OTRAS INDUSTRIAS TEXTILES NCOP' },
  { value: '441', label: 'CURTICION Y ACABADO DE CUEROS Y PIELES' },
  { value: '441', label: 'TEC. SUPERIORES URBANISTICOS Y TOPOGRAFI' },
  { value: '441.1', label: ' CUEROS Y PIELES NO ACABADAS' },
  { value: '441.2', label: ' CUEROS Y PIELES ACABADAS' },
  { value: '441.3', label: ' CUEROS Y PIELES REGENERADAS, SUBPRODUCT.' },
  { value: '442', label: 'FAB. ARTICULOS DE CUERO Y SIMILARES' },
  { value: '442.1', label: ' FAB.ART. MARROQUINERIA Y VIAJE' },
  { value: '442.2', label: ' FAB. GUANTES DE PIEL' },
  { value: '442.9', label: ' FAB. OTROS ARTICULOS DE CUERO NCOP' },
  { value: '451', label: 'FAB. SERIE CALZADO (EXCEP. CAUCHO/MADER)' },
  { value: '451', label: 'INGENIEROS EN GEODESIA Y CARTOGRAFIA' },
  { value: '451.1', label: ' PTOS. INTERMEDIOS DE FAB. CALZADO' },
  { value: '451.2', label: ' CALZADO DE CALLE FAB. EN SERIE' },
  { value: '451.3', label: ' ZAPATILLAS DE CASA, CALZADOS ESPECIALES' },
  { value: '451.4', label: ' RECORTES Y DESPERDICIOS DE CUERO' },
  { value: '452', label: 'FAB. CALZADO ARTESANIA Y A MEDIDA' },
  { value: '452.1', label: ' CALZADO ARTESANIA Y MEDIDA' },
  { value: '452.2', label: ' CALZADO ORTOPEDICO' },
  { value: '453', label: 'CONFECC. TODA CLASE PRENDAS VESTIR' },
  { value: '454', label: 'CONFECC. MEDIDA PRENDAS VESTIR' },
  { value: '454.1', label: ' PRENDAS VESTIR HECHAS A MEDIDA' },
  { value: '454.2', label: ' SOMBREROS Y ACCESORIOS PARA EL VESTIDO' },
  { value: '455', label: 'CONFEC. DE OTROS ART. CON MATERIAS TEXT.' },
  { value: '455.1', label: ' CONFECC. ART. TEXTILES HOGAR/TAPICERIA' },
  { value: '455.9', label: ' CONFECC. OTROS ART. TEXTILES NCOP' },
  { value: '456', label: 'INDUST. DE LA PELETERIA' },
  { value: '456.1', label: ' PELETERIA NATURAL' },
  { value: '456.2', label: ' PELETERIA ARTIFICIAL' },
  { value: '461', label: 'ASERRADO Y PREP. INDUSTRIAL DE LA MADERA' },
  { value: '461.1', label: ' PTOS. ASERRADO Y PREP. IND. MADERA' },
  { value: '461.2', label: ' PTOS. RESIDUALES IND. MADERA' },
  { value: '462', label: 'FAB. PRODUC. SEMIELABORADOS DE MADERA' },
  { value: '462.1', label: ' CHAPAS DE MADERA' },
  { value: '462.2', label: ' MADERAS CHAPADAS, CONTRACHAPADAS, ETC' },
  { value: '462.3', label: ' TABLEROS, PANELES DE FIBRAS Y DE PART.' },
  { value: '462.4', label: ' MADERAS MEJORADAS' },
  { value: '463', label: 'FAB. SERIE PIEZAS DE CARPINTERIA' },
  { value: '463.1', label: ' PUERTAS Y VENTANAS DE MADERA' },
  { value: '463.2', label: ' PARQUET, ENTARIMADO Y ADOQUINES MADERA' },
  { value: '463.3', label: ' OTRAS PIEZAS CARPINTERIA PARA CONSTRUCC.' },
  { value: '463.4', label: ' ELEM. ESTRUCTURALES Y PREFABRIC. MADERA' },
  { value: '464', label: 'FAB. ENVASES Y EMBALAJES DE MADERA' },
  { value: '464.1', label: ' ENVASES Y EMBALAJES INDUSTRIALES MADERA' },
  { value: '464.2', label: ' TONELERIA' },
  { value: '464.3', label: ' ESTUCHES, BAULES, MALETAS DE MADERA' },
  { value: '465', label: 'FAB. OBJETOS DIVERSOS DE MADERA' },
  { value: '465.1', label: ' OBJETOS MADERA DE USO DOMESTICO' },
  { value: '465.2', label: ' HERRAMIENTAS, MANGOS, MONTURAS DE MADERA' },
  { value: '465.3', label: ' ART. MADERA PARA FAB. CALZADO' },
  { value: '465.4', label: ' ART. MADERA PARA IND. TEXTIL' },
  { value: '465.5', label: ' CALZADO DE MADERA' },
  { value: '465.6', label: ' HARINA Y LANA DE MADERA' },
  { value: '465.9', label: ' OTROS OBJETOS MADERA NCOP' },
  { value: '466', label: 'FAB. PRODUCTOS CORCHO' },
  { value: '466.1', label: ' PRODUCTOS DE CORCHO' },
  { value: '466.2', label: ' PTOS. RESIDUALES FAB. CORCHO' },
  { value: '467', label: 'FAB. ART. JUNCO, CAÑA Y CESTERIA' },
  { value: '467.1', label: ' ARTICULOS DE MATERIAS TRENZABLES' },
  { value: '467.2', label: ' CEPILLOS, BROCHAS, ESCOBAS Y SIMILARES' },
  { value: '468', label: 'INDUST. MUEBLE DE MADERA' },
  { value: '468.1', label: ' FAB. MOBILIARIO DE MADERA PARA EL HOGAR' },
  { value: '468.2', label: ' FAB. MOBILIARIO MADERA ESCOLAR Y OFICINA' },
  { value: '468.3', label: ' FAB. MUEBLES DIV. MADERA, JUNCO, MIMBRE' },
  { value: '468.4', label: ' FAB. ATAUDES' },
  { value: '468.5', label: ' ACTIV. ANEXAS IND. MUEBLE' },
  { value: '471', label: 'FAB. PASTA PAPELERA' },
  { value: '471.1', label: ' PASTA PAPELERA' },
  { value: '471.2', label: ' SUBPRODUCTOS PASTA PAPELERA' },
  { value: '472', label: 'FAB. PAPEL Y CARTON' },
  { value: '472.1', label: ' PAPEL Y CARTON' },
  { value: '472.2', label: ' PTOS. RESIDUALES FAB. PAPEL Y CARTON' },
  { value: '473', label: 'TRANSFORMACION DE PAPEL Y CARTON' },
  { value: '473.1', label: ' FAB. CARTON ONDULADO Y SUS ARTICULOS' },
  { value: '473.2', label: ' FAB. OTROS ART. Y ENVASES PAPEL Y CARTON' },
  { value: '473.3', label: ' FAB. ART. OFICINA, ESCRITORIO, EN PAPEL' },
  { value: '473.4', label: ' FAB. ART. DECORACION EN PAPEL Y CARTON' },
  { value: '473.9', label: ' FAB. OTROS MANIPULADOS PAPEL Y CARTON' },
  { value: '474', label: 'ARTES GRAFICAS (IMPRESION GRAFICA)' },
  { value: '474.1', label: ' IMPRESION TEXTOS E IMAGENES' },
  { value: '474.2', label: ' IMPRESION DE PRENSA DIARIA' },
  { value: '474.3', label: ' REPRODUCCION TEXTOS E IMAGENES' },
  { value: '475', label: 'ACTIVIDADES ANEXAS ARTES GRAFICAS' },
  { value: '475.1', label: ' ESTEREOTIPIA, GOMAS CAUCHO, RODILLOS...' },
  { value: '475.2', label: ' COMPOSICION TEXTOS' },
  { value: '475.3', label: ' REPRODUCCION TEXTOS' },
  { value: '475.4', label: ' ENCUADERNACION' },
  { value: '476', label: 'EDICION' },
  { value: '476.1', label: ' EDICION DE LIBROS' },
  { value: '476.2', label: ' EDICION DE PERIODICOS Y REVISTAS' },
  { value: '476.9', label: ' OTRAS EDICIONES NCOP' },
  { value: '481', label: 'TRANSFORMACION DEL CAUCHO' },
  { value: '481.1', label: ' FAB. CUBIERTAS Y CAMARAS' },
  { value: '481.2', label: ' RECAUCHUTADO Y RECONSTRUCCION CUBIERTAS' },
  { value: '481.9', label: ' FAB. OTROS ART. CAUCHO NCOP' },
  { value: '482', label: 'TRANSFORMACION DE MATERIAS PLASTICAS' },
  { value: '482.1', label: ' FAB. PTOS. SEMIELABORADOS PLASTICO' },
  { value: '482.2', label: ' FAB. ART. ACABADOS MATERIAS PLASTICAS' },
  { value: '491', label: 'JOYERIA Y BISUTERIA' },
  { value: '491.1', label: ' JOYERIA' },
  { value: '491.2', label: ' BISUTERIA' },
  { value: '492', label: 'FAB. INSTRUMENTOS DE MUSICA' },
  { value: '492.1', label: 'INSTRUMENTOS DE CUERDA DE TECLADO' },
  { value: '492.2', label: 'INSTRUMENTOS DE VIENTO DE TECLADO' },
  { value: '492.3', label: 'INSTRUMENTOS DE CUERDA' },
  { value: '492.4', label: 'INSTRUMENTOS VIENTO' },
  { value: '492.5', label: 'INSTRUMENTOS PERCUSION' },
  { value: '492.6', label: 'INSTRUMENTOS MUSICALES ELECTRONICOS' },
  { value: '492.7', label: 'OTROS INSTRUMENTOS MUSICALES' },
  { value: '492.8', label: 'PARTES, PIEZAS SUELTAS INSTRUM.MUSICALES' },
  { value: '493', label: 'LABORATORIOS FOTOGRAFICOS Y CINEMAT.' },
  { value: '493.1', label: 'PELICULAS Y COPIAS CINE REVELADAS' },
  { value: '493.2', label: 'PLACAS, PELICULAS, NAGATIVAS Y DIAPOSIT.' },
  { value: '493.3', label: 'COPIAS FOTOGRAFICAS Y AMPLIACIONES' },
  { value: '494', label: 'FAB. DE JUEGOS, JUGETES Y ART. DEPORTE' },
  { value: '494.1', label: 'FAB. JUEGOS, JUGUETES Y ART. PUERICULT.' },
  { value: '494.2', label: 'FAB. ART. DEPORTE' },
  { value: '495', label: 'INDUST. MANUFACTURERAS DIVERSAS' },
  { value: '495.1', label: 'FAB. ART. ESCRITORIO' },
  { value: '495.9', label: 'FAB. OTROS ART. NCOP' },
  { value: '499', label: 'OTROS PROFES. RELACIONADOS CONSTRUCCION' },
  { value: '501', label: 'EDIFICACION Y OBRA CIVIL' },
  { value: '501.1', label: 'CONSTRUCCION COMPLETA, REPAR. Y CONSERV.' },
  { value: '501.2', label: 'CONSTRUCC. COMPLETA OBRAS CIVILES' },
  { value: '501.3', label: 'ALBAÑILERIA Y PEQ. TRABAJOS CONSTRUCCION' },
  { value: '502', label: 'TERRENOS,DEMOLICIONES,AGUAS Y PAVIMENTOS' },
  { value: '502.1', label: 'DEMOLICIONES Y DERRIBOS EN GENERAL' },
  { value: '502.2', label: 'CONSOLIDACION Y PREPARACION DE TERRENOS' },
  { value: '502.3', label: 'CONSOLID. Y PREP. TERRENOS OBRAS CIVILES' },
  { value: '502.4', label: 'CIMENTACIONES Y PAVIMENTACIONES' },
  { value: '502.5', label: 'CIMENTACIONES Y PAVIMENTAC.OBRAS CIVILES' },
  { value: '502.6', label: 'PERFORACIONES ALUMBRAMIENTO DE AGUAS' },
  { value: '503', label: 'ESTRUCTURAS,CUBIERTAS,POSTES,TORRES,ETC.' },
  { value: '503.1', label: 'PREP. MONTAJE ESTRUCTURAS Y CUBIERTAS' },
  { value: '503.2', label: 'PREP. ESTRUC. CUBIERTAS EN OBRAS CIVILES' },
  { value: '503.3', label: 'MONTAJE ESTRUC. METALICAS TTE. Y OBRAS' },
  { value: '503.4', label: 'OBRAS SIN MAQUINAS Y SIN MAS 4 OBREROS' },
  { value: '504', label: 'INSTALACIONES Y MONTAJES' },
  { value: '504.1', label: 'INSTALACIONES ELECTRICAS EN GENERAL' },
  { value: '504.2', label: 'INSTALACIONES FONTANERIA' },
  { value: '504.3', label: 'INSTALACIONES FRIO Y CALOR' },
  { value: '504.4', label: 'INSTALACIONES PARARRAYOS' },
  { value: '504.5', label: 'INSTALACION COCINAS' },
  { value: '504.6', label: 'INSTALACION APARATOS ELEVADORES' },
  { value: '504.7', label: 'INSTALACIONES TELEFONICAS' },
  { value: '504.8', label: 'MONTAJES METALICOS E INSTALAC. INDUSTR.' },
  { value: '505', label: 'ACABADO DE OBRAS' },
  { value: '505.1', label: 'REVESTIMIENTOS EXTERIORES E INTERIORES' },
  { value: '505.2', label: 'SOLADOS Y PAVIMENTOS' },
  { value: '505.3', label: 'SOLADOS Y PAVIMENTOS DE MADERA' },
  { value: '505.4', label: 'COLOCACION DE AISLAMIENTOS EN EDIFICIOS' },
  { value: '505.5', label: 'CARPINTERIA Y CERRAJERIA' },
  { value: '505.6', label: 'PINTURA, Y REVESTIMIENTOS EN PAPEL' },
  { value: '505.7', label: 'ESCAYOLA Y YESOS' },
  { value: '506', label: 'SERVICIOS AUX. DE LA CONST. Y DRAGADOS' },
  { value: '506.0', label: 'INSTALACION DE ANDAMIOS, CIMBRAS, ETC.' },
  { value: '507', label: 'CONSTRUCCION DE TODA CLASE DE OBRAS' },
  { value: '508', label: 'AGRUPACIONES Y UNIONES TEMPOR. EMPRESAS' },
  { value: '511', label: 'AGENTES COMERCIALES' },
  { value: '521', label: 'TECNICOS EN HOSTELERIA' },
  { value: '599', label: 'OTROS PROF. RELAC. COMERCIO Y HOSTELERIA' },
  { value: '611', label: 'COM. MAY. TODA CLASE DE MERCANCIAS' },
  { value: '611', label: 'AGENTES DE FERROCARRILES' },
  { value: '612', label: 'COM. MAT.PRIM.AGR.,ALIMENT.,BEB. Y TAB.' },
  { value: '612', label: 'CONDUCTORES DE VEHICULOS TERRESTRES' },
  { value: '612.1', label: ' COM.MAY.PTOS.ALIMENTIC.BEBIDAS Y TABACOS' },
  { value: '612.2', label: ' COM.MAY.CEREALES,PLANTAS,ABONOS,ANIMALES' },
  { value: '612.3', label: ' COM.MAY.FRUTAS Y VERDURAS' },
  { value: '612.4', label: ' COM.MAY. CARNES,HUEVOS,AVES Y CAZA' },
  { value: '612.5', label: ' COM.MAY.LECHE,PTOS.LACTEOS, MIEL, ACEITE' },
  { value: '612.6', label: ' COM.MAY.BEBIDAS Y TABACO' },
  { value: '612.7', label: ' COM.MAY. VINOS Y VINAGRES DEL PAIS' },
  { value: '612.8', label: ' COM.MAY. PESCADOS' },
  { value: '612.9', label: ' COM.MAY.OTROS PTOS. ALIMENT.,HELADOS ETC' },
  { value: '613', label: 'COM. TEXT., CONFEC., CALZADO Y ART.CUERO' },
  { value: '613.1', label: ' COM.MAY.PTOS.TEXTILES,CONFECC, Y CALZADO' },
  { value: '613.2', label: ' COM.MAY.TEJIDOS POR METROS, ALFOMBRAS' },
  { value: '613.3', label: ' COM.MAY. PRENDAS EXTERIORES VESTIR' },
  { value: '613.4', label: ' COM.MAY.CALZADO,PELETERIA,MARROQUINERIA' },
  { value: '613.5', label: ' COM.MAY.CAMISERIA,LENCERIA,MERCERIA,...' },
  { value: '613.9', label: ' COM.MAY.ACCESORIOS VESTIDO NCOP' },
  { value: '614', label: 'COM. PROD.FARMAC., PERFUM. Y HOGAR' },
  { value: '614.1', label: ' COM.MAY.PTOS.FARMACEUTICOS Y MEDICAMENTO' },
  { value: '614.2', label: ' COM.MAY.PTOS.PERFUMERIA,DROGUERIA' },
  { value: '614.3', label: ' COM.MAY.PTOS.MANTENIMIENTO HOGAR' },
  { value: '614.4', label: ' COM. MAY. PRODUCTOS ZOOSANITARIOS' },
  { value: '615', label: 'COM. ARTICULOS DE CONSUMO DURADERO' },
  { value: '615.1', label: ' COM.MAY.VEHICULOS Y SUS ACCESORIOS' },
  { value: '615.2', label: ' COM.MAY. DE MUEBLES' },
  { value: '615.3', label: ' COM. MAY. APARATOS ELECTRODOMESTICOS' },
  { value: '615.4', label: ' COM.MAY.APAR.Y MAT. ELECTRONICO' },
  { value: '615.5', label: ' COMERCIO AL POR MAYOR DE OBRAS DE ARTE' },
  { value: '615.6', label: ' GALERIAS DE ARTE' },
  { value: '615.9', label: ' COM.MAY.OTROS ART. CONSUMO DURADERO NCOP' },
  { value: '616', label: 'COM. INTERINDUST. DE LA MINERIA Y QUIM.' },
  { value: '616.1', label: ' COM.MAY. CARBON' },
  { value: '616.2', label: ' COM.MAY. HIERRO Y ACERO' },
  { value: '616.3', label: ' COM.MAY. MINERALES' },
  { value: '616.4', label: ' COM.MAY. METALES NO FERREOS' },
  { value: '616.5', label: ' COM.MAY. PETROLEO Y LUBRICANTES' },
  { value: '616.6', label: ' COM.MAY.PTOS. QUIMICOS INDUSTRIALES' },
  { value: '616.9', label: ' COM.MAY.PTOS.INTERINDUSTRIALES Y QUIMIC.' },
  { value: '617', label: 'OTRO COM. INTERINDUST.(EXC.MINER.Y QUI.)' },
  { value: '617.1', label: ' COM.MAY.FIBRAS TEXTILES BRUTAS' },
  { value: '617.2', label: ' COM.MAY. CUEROS Y PIELES EN BRUTO' },
  { value: '617.3', label: ' COM.MAY. MADERA Y CORCHO' },
  { value: '617.4', label: ' COM.MAY. MATERIALES CONSTRUCCION' },
  { value: '617.5', label: ' COM.MAY. MAQUINARIA PARA MADERA Y METAL' },
  { value: '617.6', label: ' COM.MAY. MAQUINARIA AGRICOLA' },
  { value: '617.7', label: ' COM.MAY. MAQUINARIA TEXTIL' },
  { value: '617.8', label: ' COM.MAY. MAQUINAS Y MATERIAL OFICINA' },
  { value: '617.9', label: ' COM.MAY.INTERINDUSTRIAL EXCEP. QUIMICA' },
  { value: '618', label: 'COMERCIALES EXPORT. Y ZONAS FRANCAS' },
  { value: '618.1', label: ' EXPORTACION TODA CLASE MERCANCIAS' },
  { value: '618.2', label: 'COM.MAY.MERCANCIAS EN ZONAS FRANCAS' },
  { value: '619', label: 'OTRO COMERCIO N.C.O.P.' },
  { value: '619.1', label: 'COM.MAY. JUGUETES Y ARTICULOS DEPORTE' },
  { value: '619.2', label: 'COM.MAY. INSTRUM. MEDICOS Y ORTOPEDICOS' },
  { value: '619.3', label: 'COM.MAY. METALES PRECIOSOS, Y JOYERIA' },
  { value: '619.4', label: 'COM.MAY.PTOS. DE PAPEL Y CARTON' },
  { value: '619.5', label: 'COM.MAY.ART.PAPELERIA Y ESCRITORIO' },
  { value: '619.6', label: 'COM.MAY. LIBROS, PERIODICOS Y REVISTAS' },
  { value: '619.7', label: 'COM.MAY.INSTRUM.PRECISION Y MEDIDA' },
  { value: '619.8', label: 'COMPRA-VENTA DE GANADO' },
  { value: '619.9', label: 'COM.MAY.OTROS PRODUCTOS NCOP' },
  { value: '621', label: 'COM.MAY.CHATARRA Y METALES DESECHO' },
  { value: '622', label: 'COM.MAY.OTROS PTOS. RECUPERACION' },
  { value: '623', label: 'RECUPERACION Y COM. RESIDUOS SIN ESTABL.' },
  { value: '631', label: 'INTERMEDIARIOS DEL COMERCIO' },
  { value: '641', label: 'COM.MEN.FRUTAS,VERDURAS' },
  { value: '642', label: 'COM. DERIV. CARNE, AVES, CONEJOS Y CAZA' },
  { value: '642.1', label: 'COM.MEN.CARNES,HUEVOS,CAZA Y GRANJA' },
  { value: '642.2', label: 'COM.MEN.CARNICERIAS-CHARCUTERIAS' },
  { value: '642.3', label: 'COM.MEN.CARNICERIAS-SALCHICHERIAS' },
  { value: '642.4', label: 'COM.MEN.CARNICERIAS' },
  { value: '642.5', label: 'COM.MEN.HUEVOS,AVES,GRANJA Y CAZA' },
  { value: '642.6', label: 'COM.MEN.CASQUERIAS' },
  { value: '643', label: 'COM. DERIV. PESCA Y CARACOLES' },
  { value: '643.1', label: 'COM.MEN.PESCADOS' },
  { value: '643.2', label: 'COM.MEN.BACALAO Y SALAZONES' },
  { value: '644', label: 'COM. PAN, PAST., CONF. Y PRODUC. LACTEOS' },
  { value: '644.1', label: 'COM.MEN.PAN,PASTELES,CONFITERIA,LACTEOS' },
  { value: '644.2', label: 'DESPACHOS PAN, PAN ESPECIAL, Y BOLLERIA' },
  { value: '644.3', label: 'COM.MEN.PTOS.PASTELERIA,BOLLERIA' },
  { value: '644.4', label: 'COM.MEN.HELADOS' },
  { value: '644.5', label: 'COM.MEN.BOMBONES Y CARAMELOS' },
  { value: '644.6', label: 'COM.MEN.MASAS FRITAS' },
  { value: '645', label: 'COM.MEN.VINOS Y BEBIDAS' },
  { value: '646', label: 'COM. TABACOS Y ARTICULOS DE FUMADOR' },
  { value: '646.1', label: 'COM.MEN.TABACOS EN EXPENDIDURIA' },
  { value: '646.2', label: 'COM.MEN.TABACO EXTENSIONES TRANSITORIAS' },
  { value: '646.3', label: 'COM.MEN.TABACO EXPENDIDURIAS COMPLEMENT.' },
  { value: '646.4', label: 'COM.MEN.TABACO CON VENTA POR RECARGO' },
  { value: '646.5', label: 'COM.MEN.TABACO MAQUINAS AUTOMATICAS' },
  { value: '646.6', label: 'COM.MEN.TABACOS VENTA NO ESTANCADA' },
  { value: '646.7', label: 'COM.MEN.ARTICULOS FUMADORES POR MINUSV.' },
  { value: '646.8', label: 'COM.MEN. ARTICULOS PARA FUMADORES' },
  { value: '647', label: 'COM. PROD. ALIMENT. Y BEBIDAS EN GENERAL' },
  { value: '647.1', label: 'COM.MEN.PTOS.ALIMENTICIOS Y BEBIDAS' },
  { value: '647.2', label: 'COM.MEN.PTOS.ALIMENTICIOS MENOS 120 M2' },
  { value: '647.3', label: 'COM.MEN.PTOS.ALIMENTICIOS 120 - 399 M2' },
  { value: '647.4', label: 'COM.MEN.PTOS.ALIMENTICIOS SUP.400 M2' },
  { value: '647.5', label: 'PTOS.ALIMENTICIOS Y BEB. MAQUINAS' },
  { value: '651', label: 'COM. PROD.TEXT.,CONF.,CALZ.,PIEL Y CUERO' },
  { value: '651.1', label: 'COM.MEN.PTOS.TEXTILES PARA EL HOGAR' },
  { value: '651.2', label: 'COM.MEN.PRENDAS DE VESTIR Y TOCADO' },
  { value: '651.3', label: 'COM.MEN.LENCERIA Y CORSETERIA' },
  { value: '651.4', label: ' COM.MEN.MERCERIA Y PAQUETERIA' },
  { value: '651.5', label: ' COM.MEN.PRENDAS ESPECIALES' },
  { value: '651.6', label: ' COM.MEN.CALZADO Y COMPLEMENTOS PIEL' },
  { value: '651.7', label: ' COM.MEN.CONFECCIONES DE PELETERIA' },
  { value: '652', label: 'COM. MEDICAM. Y PROD. FARMAC. Y HERBOL.' },
  { value: '652.1', label: ' FARMACIAS' },
  { value: '652.2', label: ' COM.MEN.PTOS.DROGUERIA,PERFUMERIA' },
  { value: '652.3', label: ' COM.MEN.PTOS.PERFUMERIA Y COSMETICA' },
  { value: '652.4', label: ' COM.MEN.PLANTAS Y HIERBAS,HERBOLARIOS' },
  { value: '653', label: 'COM. ART. PARA EQUIP. DE HOGAR Y CONST.' },
  { value: '653.1', label: ' COM.MEN.MUEBLES (EXCEPTO OFICINA)' },
  { value: '653.2', label: ' COM.MEN.APARATOS DE USO DOMESTICO' },
  { value: '653.3', label: ' COM.MEN.ART.MENAJE,FERRETERIA,ADORNO' },
  { value: '653.4', label: ' COM.MEN.MATERIALES DE CONSTRUCCION' },
  { value: '653.5', label: ' COM.MEN.PUERTAS,VENTANAS Y PERSIANAS' },
  { value: '653.6', label: ' COM. MEN. ARTICULOS DE BRICOLAGE' },
  { value: '653.9', label: ' COM.MEN.ART.HOGAR NCOP' },
  { value: '654', label: 'COM. VEHIC.,AERONAV.,EMBARC.,ACCES.,REC.' },
  { value: '654.1', label: ' COM.MEN.VEHICULOS TERRESTRES' },
  { value: '654.2', label: ' COM.MEN.ACCESORIOS Y RECAMBIOS VEHICULOS' },
  { value: '654.3', label: ' COM.MEN.VEHICULOS AEREOS' },
  { value: '654.4', label: ' COM.MEN.VEHICULOS FLUVIALES Y MARITIMOS' },
  { value: '654.5', label: ' COM.MEN.DE TODA CLASE DE MAQUINARIA' },
  { value: '654.6', label: ' COM.MEN.CUBIERTAS,BANDAS Y CAMARAS AIRE' },
  { value: '655', label: 'COM. COMBUST., CARBURANTES Y LUBRICANTES' },
  { value: '655.1', label: ' COM.MEN.COMBUSTIBLES TODAS CLASES' },
  { value: '655.2', label: ' COM.MEN.GASES COMBUSTIBLES' },
  { value: '655.3', label: ' COM.MEN.CARBURANTES Y ACEITES VEHICULOS' },
  { value: '656', label: 'COM. MEN. DE BIENES USADOS' },
  { value: '657', label: 'COM.MEN. INSTRUMENTOS MUSICA Y ACCESORIO' },
  { value: '659', label: 'OTRO COMERCIO AL POR MENOR' },
  { value: '659.1', label: ' COM.MEN.SELLOS,MONEDAS,MEDALLAS,COLECC.' },
  { value: '659.2', label: ' COM.MEN.MUEBLES Y MAQUINAS DE OFICINA' },
  { value: '659.3', label: ' COM.MEN.APARATOS MEDICOS, ORTOPEDICOS' },
  { value: '659.4', label: ' COM.MEN.LIBROS,PERIODICOS,REVISTAS.' },
  { value: '659.5', label: ' COM.MEN.ART.JOYERIA,RELOJERIA,BISUTERIA' },
  { value: '659.6', label: ' COM.MEN.JUGUETES,ART.DEPORTE,ARMAS...' },
  { value: '659.7', label: ' COM.MEN.SEMILLAS,ABONOS,FLORES,PLANTAS' },
  { value: '659.8', label: 'COM.MEN."SEX-SHOP"' },
  { value: '659.9', label: ' COM.MEN.OTROS PTOS. NCOP' },
  { value: '661', label: 'COM. MIXTO O INTEGRADO EN GRAND. SUPERF.' },
  { value: '661.1', label: ' COM.MEN.GRANDES ALMACENES' },
  { value: '661.2', label: ' COM.MEN.EN HIPERMERCADOS' },
  { value: '661.3', label: ' COM.MEN.ALMACENES POPULARES' },
  { value: '662', label: 'COMERCIO MIXTO O INTEGRADO AL POR MENOR' },
  { value: '662.1', label: ' COM.MEN.EN ECONOMATOS Y COOP. DE CONSUMO' },
  { value: '662.2', label: ' COM.MEN.TODA CLASE ART. EN OTROS LOCALES' },
  { value: '663', label: 'COM. FUERA DE UN ESTABLECIMIENTO' },
  { value: '663.1', label: ' COM.MEN.PTOS.ALIMENTICIOS SIN ESTABLEC.' },
  { value: '663.2', label: ' COM.MEN.TEXTILES Y CONFECC.SIN ESTABLEC.' },
  { value: '663.3', label: ' COM.MEN.CALZADO SIN ESTABLECIMIENTO' },
  { value: '663.4', label: ' COM.MEN.DROGUERIA SIN ESTABLECIMIENTO' },
  { value: '663.9', label: ' COM.MEN.OTRAS MERCANCIAS SIN ESTABLECIM.' },
  { value: '664', label: 'COM. EN EXPOSIT. Y APARAT. AUTOMATICOS' },
  { value: '664.1', label: 'VENTA ARTICULOS DIVERSOS EN EXPOSITORES' },
  { value: '664.9', label: 'COM.MEN.ART.DIVERSOS EN MAQUINAS' },
  { value: '665', label: 'COM.MEN.POR CORREO O CATALOGO' },
  { value: '671', label: 'EN RESTAURANTES' },
  { value: '671.1', label: 'RESTAURANTES CINCO TENEDORES' },
  { value: '671.2', label: 'RESTAURANTES CUATRO TENEDORES' },
  { value: '671.3', label: 'RESTAURANTES DE TRES TENEDORES' },
  { value: '671.4', label: 'RESTAURANTES DE DOS TENEDORES' },
  { value: '671.5', label: 'RESTAURANTES DE UN TENEDOR' },
  { value: '672', label: 'EN CAFETERIAS' },
  { value: '672.1', label: 'CAFETERIAS TRES TAZAS' },
  { value: '672.2', label: 'CAFETERIAS DOS TAZAS' },
  { value: '672.3', label: 'CAFETERIAS DE UNA TAZA' },
  { value: '673', label: 'EN CAFES, BARES, CON Y SIN COMIDA' },
  { value: '673.1', label: 'BARES CATEGORIA ESPECIAL' },
  { value: '673.2', label: 'OTROS CAFES Y BARES' },
  { value: '674', label: 'ESPECIALES EN REST., CAFET. Y CAFE-BAR' },
  { value: '674.1', label: 'CAFE-BAR EN VEHICULO' },
  { value: '674.2', label: 'CAFE-BAR EN FERROCARRILES' },
  { value: '674.3', label: 'CAFE-BAR EN BARCOS' },
  { value: '674.4', label: 'CAFE-BAR AERONAVES' },
  { value: '674.5', label: 'CAFE-BAR SOCIEDADES,CASINOS,CLUBES...' },
  { value: '674.6', label: 'CAFE-BAR TEATROS Y CINES' },
  { value: '674.7', label: 'SERVICIOS EN PARQUES O RECINTOS FERIALES' },
  { value: '675', label: 'CAFES-BARES EN QUIOSCOS,CAJONES,BARRACAS' },
  { value: '676', label: 'CHOCOLATERIAS,HELADERIAS Y HORCHATERIAS' },
  { value: '677', label: 'S.PRESTADOS FUERA ESTABLEC. O.SERV.ALIM.' },
  { value: '677.1', label: 'HOSTELERIA Y RESTAURACION SIN ESTABLEC.' },
  { value: '677.9', label: 'OTROS SERV. ALIMENTACION - RESTAURACION' },
  { value: '681', label: 'HOSPEDAJE EN HOTELES Y MOTELES' },
  { value: '682', label: 'HOSPEDAJE EN HOSTALES Y PENSIONES' },
  { value: '683', label: 'HOSPEDAJES EN FONDAS Y CASAS HUESPEDES' },
  { value: '684', label: 'HOSPEDAJE EN APARTA-HOTELES' },
  { value: '685', label: 'ALOJAMIENTOS TURISTICOS EXTRAHOTELEROS' },
  { value: '686', label: 'EXPLOTACION APARTAMENTOS PRIVADOS' },
  { value: '687', label: 'CAMPAMENTOS TURISTICOS' },
  { value: '687.1', label: 'CAMPAMENTOS DE LUJO' },
  { value: '687.2', label: 'CAMPAMENTOS DE PRIMERA CLASE' },
  { value: '687.3', label: 'CAMPAMENTOS DE SEGUNDA CLASE' },
  { value: '687.4', label: 'CAMPAMENTOS DE TERCERA CLASE' },
  { value: '691', label: 'REP.ART.ELEC. HOGAR,VEHIC.Y OTROS BIENES' },
  { value: '691.1', label: 'REPARACION ART. ELECTRODOMESTICOS' },
  { value: '691.2', label: 'REPARACION AUTOMOVILES Y BICICLETAS' },
  { value: '691.9', label: 'REPARACION OTROS BIENES CONSUMO NCOP' },
  { value: '692', label: 'REPARACION DE MAQUINARIA INDUSTRIAL' },
  { value: '699', label: 'OTRAS REPARACIONES NCOP' },
  { value: '699', label: 'OTROS PROFESIONALES RELAC. TRANSPORTE' },
  { value: '711', label: 'TTE. FERROCARRIL DE VIA NORMAL' },
  { value: '711', label: 'ACTUARIOS DE SEGUROS' },
  { value: '711.1', label: 'TTE.FERROVIARIO DE VIAJEROS VIA NORMAL' },
  { value: '711.2', label: 'TTE.FERROVIARIO MERCACIAS VIA NORMAL' },
  { value: '712', label: 'TTE. FERROVIARIO DE VIA ESTRECHA' },
  { value: '712', label: 'AGENTES Y CORREDORES DE SEGUROS' },
  { value: '712.1', label: 'TTE.FERROVIARIO VIAJEROS VIA ESTRECHA' },
  { value: '712.2', label: 'TTE.FERROVIARIO MERCANCIAS VIA ESTRECHA' },
  { value: '721', label: 'TRANSPORTE DE VIAJEROS' },
  { value: '721', label: 'AGENTES COLEGIADOS PROPIEDAD IND.E INMOB' },
  { value: '721.1', label: ' TTE. URBANO COLECTIVO' },
  { value: '721.2', label: ' TTE. POR AUTOTAXIS' },
  { value: '721.3', label: ' TTE. VIAJEROS POR CARRETERA' },
  { value: '721.4', label: ' TRANSPORTE SANITARIO EN AMBULANCIAS' },
  { value: '722', label: 'TTE. MERCANCIAS POR CARRETERA' },
  { value: '722', label: 'GESTORES ADMINISTRATIVOS' },
  { value: '723', label: 'ADMINISTRADORES DE FINCAS' },
  { value: '724', label: 'INTERMEDIARIOS PROMOCION EDIFICACIONES' },
  { value: '725', label: 'HABILITADOS DE CLASES PASIVAS' },
  { value: '726', label: 'GRADUADOS SOCIALES' },
  { value: '727', label: 'AGENTES O INTERMEDIARIOS PRESTAMOS' },
  { value: '728', label: 'AGENTES DE ADUANAS' },
  { value: '729', label: 'OTROS TTES. TERRESTRES NCOP' },
  { value: '729.1', label: ' TTE. FERROCARRIL DE CREMALLERA' },
  { value: '729.2', label: ' TTE. TELEFERICOS Y FUNICULARES' },
  { value: '729.3', label: ' OTROS SERVICIOS DE TTE. TERRESTRE' },
  { value: '731', label: 'TRANSP. MARIT.INTERN.(EXC.CRUD.Y GASES)' },
  { value: '731', label: 'ABOGADOS' },
  { value: '731.1', label: ' TTE. MARITIMO INTERNACIONAL PASAJEROS' },
  { value: '731.2', label: ' TTE. MARITIMO INTERNACIONAL MERCANCIAS' },
  { value: '732', label: 'TTE. MARITIMO DE CRUDOS Y GASES' },
  { value: '732', label: 'PROCURADORES' },
  { value: '732.1', label: ' TTE. MARITIMO INTERNACIONAL PTOS PETROL.' },
  { value: '732.2', label: ' TTE. CABOTAJE PTOS. PETROLIFEROS Y GAS' },
  { value: '732.3', label: ' TTE. VIAS NAVEGABLES INTERIOR PETROL Y G' },
  { value: '733', label: 'TRANSP. CABOT. Y V.N.(EXC.CRUD.Y GASES)' },
  { value: '733', label: 'NOTARIOS' },
  { value: '733.1', label: ' TTE. CABOTAJE Y VIAS INTERIOR VIAJEROS' },
  { value: '733.2', label: ' TTE. CABOTAJE Y VIAS INTERIOR MERCANCIAS' },
  { value: '733.3', label: ' TRANSBORDADORES, FERRY BOATS Y SIMIL' },
  { value: '733.4', label: ' TTE.MARITIMO PASAJEROS EN JET-FOIL' },
  { value: '734', label: 'REGISTRADORES' },
  { value: '741', label: 'TRANSPORTE AEREO REGULAR' },
  { value: '741', label: 'ECONOMISTAS' },
  { value: '741.1', label: 'TTE. AEREO NACIONAL DE VIAJEROS' },
  { value: '741.2', label: 'TTE. AEREO NACIONAL MERCANCIAS' },
  { value: '741.3', label: 'TTE. AEREO INTERNACIONAL DE VIAJEROS' },
  { value: '741.4', label: 'TTE. AEREO INTERNACIONAL MERCANCIAS' },
  { value: '742', label: 'TRANSPORTE AEREO NO REGULAR' },
  { value: '742', label: 'INTENDENTES Y PROFESORES MERCANTILES' },
  { value: '742.1', label: ' TTE. AEREO NACIONAL VIAJEROS' },
  { value: '742.2', label: ' TTE. AEREO NACIONAL DE MERCANCIAS' },
  { value: '742.3', label: ' TTE. AEREO INTERNAC.VIAJEROS CHARTER' },
  { value: '742.4', label: ' TTE. AEREO INTERNAC. MERCANCIAS CHARTER' },
  { value: '743', label: 'PERITOS MERCANTILES' },
  { value: '744', label: 'DIPLOMADOS EN CIENCIAS EMPRESARIALES' },
  { value: '746', label: 'CORREDORES DE COMERCIO LIBRES' },
  { value: '747', label: 'AUDITORES DE CUENTAS Y CENSORES JURADOS' },
  { value: '748', label: 'ADMINISTRADORES DE CARTERAS DE VALORES' },
  { value: '749', label: 'CORREDORES INTERPRETES Y MARITIMOS' },
  { value: '751', label: 'ACT. ANEXAS AL TRANSP. TERRESTRE' },
  { value: '751', label: 'PROFESIONALES PUBLICIDAD, RELAC.PUBLICAS' },
  { value: '751.1', label: ' GUARDIA Y CUSTODIA VEHICULOS EN GARAJES' },
  { value: '751.2', label: ' GUARDIA Y CUSTODIA VEHICULOS PARKING' },
  { value: '751.3', label: ' GUARDIA Y CUSTODIA VEHICULOS EN SOLARES' },
  { value: '751.4', label: ' EXPLOTACION AUTOPISTAS Y TUNELES PEAJE' },
  { value: '751.5', label: ' ENGRASE Y LAVADO DE VEHICULOS' },
  { value: '751.6', label: ' SERVICIOS DE CARGA Y DESCARGA MERCANCIAS' },
  { value: '752', label: 'ACT. ANEXAS AL TRANSP. MARIT. Y V. N. I.' },
  { value: '752.1', label: ' SERVICIOS PILOTAJE Y PRACTICOS PUERTOS' },
  { value: '752.2', label: ' SERVICIOS TRANSBORDO DE BARCOS' },
  { value: '752.3', label: ' SERVICIOS DE REMOLQUE DE NAVIOS' },
  { value: '752.4', label: ' SERVICIOS LIMPIEZA Y DESINFECCION BARCOS' },
  { value: '752.5', label: ' SERV. SALVAMENTO Y RECUPERACION BARCOS' },
  { value: '752.6', label: ' SERVICIOS CARGA Y DESCARGA DE BUQUES' },
  { value: '752.7', label: ' EXPLOTACION DE PUERTOS,CANALES Y DIQUES' },
  { value: '752.8', label: ' SERV. SEÑALES MARITIMAS Y COSTERAS' },
  { value: '753', label: 'ACT. ANEXAS AL TRANSP. AEREO' },
  { value: '753.1', label: ' TERMINALES LINEAS TTE. AEREO AEROPUERTOS' },
  { value: '753.2', label: ' SERVICIO DE CONTROL DE NAVEGACION AEREA' },
  { value: '753.3', label: ' SERV. HANGARES Y ESTACIONAMIENTO AVIONES' },
  { value: '753.4', label: ' SERV. REMOLQUE, Y MANTENIMIENTO AVIONES' },
  { value: '753.5', label: 'EXPLOTACION INTEGRAL DE AEROPUERTOS' },
  { value: '753.9', label: 'OTROS SERV. ANEXOS AL TTE. AEREO NCOP' },
  { value: '754', label: 'DEPOSITOS Y ALMACENES DE MERCANCIAS' },
  { value: '754.1', label: 'DEPOSITOS Y ALMACENES GENERALES' },
  { value: '754.2', label: 'DEPOSITOS Y ALMACENES DE VEHICULOS' },
  { value: '754.3', label: 'SILOS Y OTROS ALMACENES DE GRANOS' },
  { value: '754.4', label: 'ALMACENES FRIGORIFICOS' },
  { value: '754.5', label: 'ALMACENES Y DEPOSITOS DE LIQUIDOS' },
  { value: '754.6', label: 'GUARDAMUEBLES' },
  { value: '754.9', label: 'OTROS DEPOSITOS ESPECIALES NCOP' },
  { value: '755', label: 'AGENCIAS DE VIAJES' },
  { value: '755.1', label: 'SERVICIOS A OTRAS AGENCIAS DE VIAJES' },
  { value: '755.2', label: 'SERV. AL PUBLICO DE AGENCIAS DE VIAJES' },
  { value: '756', label: 'ACTIV. AUXILIARES Y COMPLEMENTARIAS TTE.' },
  { value: '756.1', label: 'AGENCIAS DE TTE., TRANSITARIOS' },
  { value: '756.2', label: 'CONSIGNATARIOS DE BUQUES' },
  { value: '756.9', label: 'OTROS SERVICIOS DE MEDIACION DEL TTE.' },
  { value: '757', label: 'SERVICIO DE MUDANZAS' },
  { value: '761', label: 'SERVICIOS TELEFONICOS' },
  { value: '761', label: 'DOCTORES Y LICENCIADOS EXACTAS Y ESTAD.' },
  { value: '761.1', label: 'SERVICIOS DE TELEFONIA FIJA' },
  { value: '761.2', label: 'SERVICIOS DE TELEFONIA MOVIL' },
  { value: '762', label: 'DOCTORES,LICENCIADOS,INGENIEROS INFORMAT' },
  { value: '763', label: 'PROGRAMADORES Y ANALISTAS INFORMATICA' },
  { value: '764', label: 'DIPLOMADOS EN INFORMATICA' },
  { value: '765', label: 'GRABADORES, Y OTROS PROF. INFORMATICA' },
  { value: '769', label: 'OTROS SERVICIOS DE TELECOMUNICACION' },
  { value: '769.1', label: 'TELECOMUNICACION MOVIL' },
  { value: '769.2', label: 'TELETRANSMISION DE DATOS' },
  { value: '769.3', label: 'TELECOMUNICACION VIA SATELITE' },
  { value: '769.9', label: 'OTROS SERV. PRIVADOS TELECOMUNICACION' },
  { value: '771', label: 'AGENTES COBRADORES' },
  { value: '772', label: 'ESTENOTIPISTAS, TAQUIMECANOGRAFOS,...' },
  { value: '773', label: 'DETECTIVES PRIVADOS, VIGILANCIA, PROTECC' },
  { value: '774', label: 'TRADUCTORES E INTERPRETES' },
  { value: '775', label: 'DOCTORES Y LICENCIADOS EN FILOSOFIA' },
  { value: '776', label: 'DRES.LICENC.POLITICAS,SOCIALES,LETRAS' },
  { value: '777', label: 'ESPECIALISTAS ORIENTACION Y ANALISIS PER' },
  { value: '778', label: 'DIPL. EN BIBLIOTECOMANIA Y DOCUMENTACION' },
  { value: '799', label: 'OTROS PROF. ACTIV. FINANC. JURID. SEGUR.' },
  { value: '811', label: 'BANCA' },
  { value: '811', label: 'PROFESIONALES LIMPIEZA' },
  { value: '812', label: 'CAJAS DE AHORRO' },
  { value: '819', label: 'OTRAS INSTITUCIONES FINANCIERAS' },
  { value: '819.1', label: 'INSTITUCIONES DE CREDITO' },
  { value: '819.2', label: 'ESTABLECIMIENTOS FINANCIEROS DE CREDITO' },
  { value: '819.3', label: 'ESTABLEC.FINANCIEROS CREDITO "PRESTAMOS"' },
  { value: '819.4', label: 'ESTABLEC.FINANCIEROS CREDITO "FACTORING"' },
  { value: '819.5', label: 'ESTABLEC.FINANCIEROS CREDITO "ARRENDAM."' },
  { value: '819.6', label: 'ENTIDADES DE CAMBIO DE MONEDA' },
  { value: '819.9', label: 'OTRAS ENTIDADES FINANCIERAS NCOP' },
  { value: '821', label: 'ENTIDADES ASEGURADORES DE VIDA Y CAPIT.' },
  { value: '821', label: 'PERSONAL DOCENTE ENSEÑANZA SUPERIOR' },
  { value: '821.1', label: 'SEGUROS DE VIDA' },
  { value: '821.2', label: 'SEGUROS DE CAPITALIZACION' },
  { value: '821.3', label: 'SEGUROS MIXTOS DE VIDA Y CAPITALIZACION' },
  { value: '822', label: 'ASEGURADORAS DE ENFERMEDAD Y RIESGOS' },
  { value: '822', label: 'PERSONAL DOCENTE ENSEÑANZA MEDIA' },
  { value: '822.1', label: 'SEGUROS DE ASISTENCIA SANITARIA' },
  { value: '822.2', label: 'SEGUROS DE ENTIERRO' },
  { value: '822.3', label: 'SEGUROS DE DAÑOS MATERIALES' },
  { value: '822.4', label: 'SEGUROS DE TRANSPORTES' },
  { value: '822.9', label: 'OTROS SEGUROS' },
  { value: '823', label: 'OTRAS ENTIDADES ASEGURADORAS' },
  { value: '823', label: 'DOCENTES ENSEÑANZA GRAL. BASICA' },
  { value: '824', label: 'PROFESORES FORMACION PROFESIONAL' },
  { value: '825', label: 'PROFESORES CONDUCCION VEHICULOS' },
  { value: '826', label: 'PERSONAL DOCENTE ENSEÑANZAS DIVERSAS' },
  { value: '831', label: 'AUXILIARES FINANCIEROS' },
  { value: '831', label: 'MEDICOS MEDICINA GENERAL' },
  { value: '831.1', label: 'SERV.COMPRA-VTA. DE VALORES MOBILIARIOS' },
  { value: '831.2', label: 'SER. FINANC. CONTRATACION PRODUCTOS' },
  { value: '831.3', label: 'SERV. DE COMPENSACION BANCARIA' },
  { value: '831.9', label: 'OTROS SERVICIOS FINANCIEROS NCOP' },
  { value: '832', label: 'AUXILIARES DE SEGUROS' },
  { value: '832', label: 'MEDICOS ESPECIALISTAS' },
  { value: '832.1', label: ' AGENCIAS DE SEGUROS Y CORREDURIAS' },
  { value: '832.2', label: ' SERV. TASACION Y TARIFICACION SEGUROS' },
  { value: '832.9', label: ' OTROS SERV. AUXILIARES DE SEGUROS' },
  { value: '833', label: 'PROMOCION INMOBILIARIA' },
  { value: '833', label: 'ESTOMATOLOGOS' },
  { value: '833.1', label: ' PROMOCION INMOBILIARIA DE TERRENOS' },
  { value: '833.2', label: ' PROMOCION INMOBILIARIA DE EDIFICACIONES' },
  { value: '834', label: 'SERV. PROPIEDAD INMOBILIARIA E INDUSTRIA' },
  { value: '834', label: 'ODONTOLOGOS' },
  { value: '835', label: 'FARMACEUTICOS' },
  { value: '836', label: 'AYUD. TECN. SANITARIOS Y FISIOTERAPEUTAS' },
  { value: '837', label: 'PROTESICOS E HIGIENISTAS DENTALES' },
  { value: '838', label: 'OPTICOS-OPTOMETRISTAS Y PODOLOGOS' },
  { value: '839', label: 'MASAJISTAS, DIETISTAS, Y AUX.ENFERMERIA' },
  { value: '841', label: 'SERVICIOS JURIDICOS' },
  { value: '841', label: 'ACUPUNTORES, NATUROPATAS Y OTROS' },
  { value: '842', label: 'SERVICIOS FINANCIEROS Y CONTABLES' },
  { value: '843.1', label: ' SERVICIOS TECNICOS DE INGENIERIA' },
  { value: '843.2', label: ' SERV. TEC. DE ARQUITECTURA' },
  { value: '843.3', label: ' SERV. TEC. PROSPECCIONES Y GEOLOGIA' },
  { value: '843.4', label: ' SERV. TECNICOS DE TOPOGRAFIA' },
  { value: '843.5', label: ' SERV. TECNICOS DE DELINEACION' },
  { value: '843.6', label: ' INSPECCION TECNICA DE VEHICULOS' },
  { value: '843.9', label: ' OTROS SERVICIOS TECNICOS NCOP' },
  { value: '844', label: 'SERVICIOS PUBLICIDAD, RELACIONES PUBLIC.' },
  { value: '845', label: 'EXPLOTACION ELECTRONICA POR TERCEROS' },
  { value: '846', label: 'EMPRESAS DE ESTUDIO DE MERCADO' },
  { value: '847', label: 'SERV.INTEGRALES DE CORREOS Y TELECOMUNIC' },
  { value: '849', label: 'OTROS SERVICIOS PRESTADOS N.C.O.P.' },
  { value: '849.1', label: ' COBROS DE DEUDAS Y CONFECCION FACTURAS' },
  { value: '849.2', label: ' SERV. MECANOGRAFICOS, TAQUIGRAFICOS, ETC' },
  { value: '849.3', label: ' SERV. TRADUCCION Y SIMILARES' },
  { value: '849.4', label: ' SERV. CUSTODIA, SEGURIDAD Y PROTECCION' },
  { value: '849.5', label: ' SERVICIOS MENSAJERIA,RECADER., Y REPARTO' },
  { value: '849.6', label: ' SERV.COLOCACION Y SUMINISTRO DE PERSONAL' },
  { value: '849.7', label: ' SERV. GESTION ADMINISTRATIVA' },
  { value: '849.8', label: ' MULTISERVICIOS INTENSIVOS EN PERSONAL' },
  { value: '849.9', label: ' OTROS SERVICIOS INDEPENDIENTES NCOP' },
  { value: '851', label: 'ALQUILER MAQUINARIA Y EQUIPO AGRICOLA' },
  { value: '851', label: 'REPRESENTANTES TECNICOS DEL ESPECTACULO' },
  { value: '852', label: 'ALQUILER MAQUINARIA Y EQUIP. CONSTRUCC.' },
  { value: '852', label: 'APODERADOS Y REPRESENTANTES TAURINOS' },
  { value: '853', label: 'ALQUILER MAQ. Y EQUIPO CONTABLE' },
  { value: '853', label: 'AGENTES COLOCADORES ARTISTAS' },
  { value: '854', label: 'ALQUILER AUTOMOVILES SIN CONDUCTOR' },
  { value: '854', label: 'EXPERTOS ORGANIZACION CONGRESOS' },
  { value: '854.1', label: ' ALQUILER AUTOMOVILES SIN CONDUCTOR' },
  { value: '854.2', label: 'ALQUILER AUTOMOVILES EN"RENTING"' },
  { value: '855', label: 'ALQUILER DE OTROS MEDIOS DE TRANSPORTE' },
  { value: '855', label: 'AGENTES Y CORREDORES APUESTAS' },
  { value: '855.1', label: ' ALQUILER DE AERONAVES DE TODAS CLASES' },
  { value: '855.2', label: ' ALQUILER DE EMBARCACIONES' },
  { value: '855.3', label: ' ALQUILER DE BICICLETAS' },
  { value: '855.9', label: ' ALQUILER OTROS MEDIOS TRANSP. N.C.O.P.' },
  { value: '856', label: 'ALQUILER DE BIENES DE CONSUMO' },
  { value: '856.1', label: ' ALQUILER DE BIENES DE CONSUMO' },
  { value: '856.2', label: ' ALQUILER DE PELICULAS DE VIDEO' },
  { value: '857', label: 'ALQUILER DE APARATOS DE MEDIDA' },
  { value: '857.1', label: ' ALQUILER DE APARATOS DE MEDIDA' },
  { value: '857.2', label: ' SERV.DE PESA A MEDIDA SIN ALQUILER' },
  { value: '857.3', label: ' ALQUILER CONTADORES PARA AUTOMOVILES' },
  { value: '857.4', label: ' ALQUILER, LEC., CONS., CONTADORES DE LUZ' },
  { value: '857.5', label: ' LECTURA Y CONS. CONTAD. LUZ TANTO ALZADO' },
  { value: '857.6', label: ' ALQUILER, LEC. Y CONS. CONTADORES GAS' },
  { value: '857.7', label: ' LECTURA Y CONS. DE CONTADORES DE GAS' },
  { value: '857.8', label: ' ALQUILER, LEC. Y CONS. CONTADORES AGUA' },
  { value: '857.9', label: 'LECTURA Y CONS. DE CONTADORES DE AGUA' },
  { value: '859', label: 'ALQUILER OTROS BIENES MUEBLES NCOP' },
  { value: '861', label: 'ALQ. DE BIENES INMUEBLES DE NAT. URBANA' },
  { value: '861', label: 'PINTORES,ESCULTORES,CERAMISTAS,ARTESANOS' },
  { value: '861.1', label: 'ALQUILER DE VIVIENDAS' },
  { value: '861.2', label: 'ALQUILER LOCALES INDUSTRIALES' },
  { value: '862', label: 'ALQUILER INMUEBLES RUSTICOS' },
  { value: '862', label: 'RESTAURADORES OBRAS DE ARTE' },
  { value: '871', label: 'EXPENDEDORES OFICIALES LOTERIAS' },
  { value: '872', label: 'EXPENDEDORES OFICIALES APUESTAS' },
  { value: '873', label: 'EXPENDEDORES NO OFICIALES APUESTAS' },
  { value: '881', label: 'ASTROLOGOS Y SIMILARES' },
  { value: '882', label: 'GUIAS DE TURISMO' },
  { value: '883', label: 'GUIAS INTERPRETES DE TURISMO' },
  { value: '884', label: 'PERITOS TASADORES:SEGUROS,ALHAJAS,...' },
  { value: '885', label: 'LIQUIDADORES Y COMISARIOS DE AVERIAS' },
  { value: '886', label: 'CRONOMETRADORES' },
  { value: '887', label: 'MAQUILLADORES Y ESTETICISTAS' },
  { value: '888', label: 'GRAFOLOGOS' },
  { value: '899', label: 'OTROS PROF. RELACIONADOS CON SERVICIOS' },
  { value: '911', label: 'SERVICIOS AGRICOLAS Y GANADEROS' },
  { value: '912', label: 'SERVICIOS FORESTALES,PESCA Y ACUICULTURA' },
  { value: '921', label: 'SERV. SANEAMIENTO DE VIAS PUBLICAS' },
  { value: '921.1', label: 'SERV. LIMPIEZA DE VIAS Y JARDINES' },
  { value: '921.2', label: 'SERV. RECOGIDA DE BASURAS Y DESECHOS' },
  { value: '921.3', label: 'EXTERMINIO ANIMALES DAÑINOS Y DESIFECC.' },
  { value: '921.4', label: 'SERV.ALCANTARILLADO, EVACUACION AGUAS' },
  { value: '921.5', label: 'SERVICIOS INCINERACION, ELIM. BASURAS' },
  { value: '921.6', label: 'SERV.PROTECCION Y ACONDIC. AMBIENTAL' },
  { value: '921.7', label: 'SERV. PROTECCION CONTRA INCENDIOS' },
  { value: '921.8', label: 'SERV. ADMON. CEMENTERIOS' },
  { value: '921.9', label: 'OTROS SERVICIOS DE SANEAMIENTO N.C.O.P.' },
  { value: '922', label: 'SERVICIOS DE LIMPIEZA' },
  { value: '922.1', label: 'SERV. LIMPIEZA INTERIORES' },
  { value: '922.2', label: 'SERV. ESPECIALIZADOS DE LIMPIEZA' },
  { value: '931', label: 'ENSE#ANZA REGLADA' },
  { value: '931.1', label: 'GUARDERIA Y ENSEÑANZA INFANTIL,EXCLUSIV.' },
  { value: '931.2', label: 'ENSEÑANZA DE EDUCACION BASICA' },
  { value: '931.3', label: 'ENSEÑANZA BACHILLERATO, F.P., Y C.O.U.' },
  { value: '931.4', label: 'ENSEÑANZA REGLADA (PREESC,EGB,BUP,FP,COU' },
  { value: '931.5', label: 'ENSEÑANZA DE EDUCACION SUPERIOR' },
  { value: '932', label: 'ENSE#ANZA NO REGLADA Y EDUCACION SUPER.' },
  { value: '932.1', label: 'ENSEÑANZA FORMACION PROF. NO SUPERIOR' },
  { value: '932.2', label: 'ENSEÑANZA FORM. PROFESIONAL SUPERIOR' },
  { value: '933', label: 'OTRA ACTIVIDADES DE ENSEÑANZA' },
  { value: '933.1', label: 'ENSEÑANZA CONDUCCION VEHICULOS' },
  { value: '933.2', label: 'PROMOC. CURSOS Y ESTUDIOS EN EL EXTRANJ.' },
  { value: '933.9', label: 'OTROS ACTIV. ENSEÑANZA' },
  { value: '934', label: 'ENSEÑANZA FUERA ESTABLECIMIENTO PERMANEN' },
  { value: '935', label: 'COLEGIOS MAYORES Y RESIDENCIAS DE ESTUD.' },
  { value: '935.1', label: 'COLEGIOS MAYORES' },
  { value: '935.2', label: 'RESIDENCIAS DE ESTUDIANTES' },
  { value: '936', label: 'INVESTIGACION CIENTIFICA Y TECNICA' },
  { value: '936.1', label: 'INVESTIGACION CC. EXACTAS Y NATURALES' },
  { value: '936.2', label: 'INVESTIGACION CC. MEDICAS' },
  { value: '936.3', label: 'INVESTIGACIONES AGRARIAS' },
  { value: '936.4', label: 'INVEST. CC. SOCIALES,HUMANIDADES' },
  { value: '936.5', label: 'INVESTIGACION TECNICA INDUSTRIAL' },
  { value: '936.9', label: 'OTRAS INVESTIGACIONES CIENTIFICAS NCOP' },
  { value: '941', label: 'HOSPITALES,CLINICAS Y SANATORIOS' },
  { value: '941.1', label: 'HOSPITALES GENERALES' },
  { value: '941.2', label: 'HOSPITALES ESPECIALIZADOS' },
  { value: '942', label: 'OTROS ESTAB.SANITARIOS,BALNEARIOS,BAÑOS' },
  { value: '942.1', label: 'CONSULTORIOS MEDICOS, SANITARIOS...' },
  { value: '942.2', label: 'BALNEARIOS Y BAÑOS' },
  { value: '942.9', label: 'OTROS SERV. SANITARIOS' },
  { value: '943', label: 'CONSULTAS CLINICAS ESTOMAT. Y ODONTOLOG.' },
  { value: '944', label: 'SERV. NATUROPATIA,ACUPUNTURA,PARASANIT.' },
  { value: '945', label: 'CONSULTAS Y CLINICAS VETERINARIAS' },
  { value: '951', label: 'ASISTENCIA Y SERV. SOCIALES DISMINUIDOS' },
  { value: '952', label: 'ASIST. Y SERV. DISMINUIDOS NO RESIDENC.' },
  { value: '961', label: 'PRODUC. Y SERV. PELIC. CINEM.(I. VIDEOS)' },
  { value: '961.1', label: ' PRODUCCION PELICULAS CINEMATOGRAFICAS' },
  { value: '961.2', label: ' DOBLAJE, MONTAJE DE PELICULAS' },
  { value: '961.3', label: ' DECORACIONES ESCENICAS PELICULAS' },
  { value: '962', label: 'DISTRIBUCION PELIC. CINEM. Y VIDEOS' },
  { value: '962.1', label: ' DISTRIBUCION Y VENTA DE PELICULAS' },
  { value: '962.2', label: ' DISTRIBUCION Y VTA MAY. PELICULAS' },
  { value: '963', label: 'EXHIBICION PELIC. CINEM. Y VIDEOS' },
  { value: '963.1', label: ' EXHIBICION DE PELICULAS CINEMATOGRAFICAS' },
  { value: '963.2', label: ' EXHIBICION PELICULAS AIRE LIBRE' },
  { value: '963.3', label: ' EXHIBICION PELICULAS SIN ESTABLECIMIENTO' },
  { value: '963.4', label: ' EXHIBICION PELICULAS ESTABLEC. DISTINTOS' },
  { value: '964', label: 'SERV. RADIODIF. TV. Y ENLACE Y TRANS. TV' },
  { value: '964.1', label: ' SERVICIOS DE RADIODIFUSION' },
  { value: '964.2', label: ' SERVICIOS DE TELEVISION' },
  { value: '964.3', label: ' SERV.TRANSMISION Y ENLACE SEÑALES TV' },
  { value: '965', label: 'ESPECTACULOS (EXCEPTO CINE Y DEPORTES)' },
  { value: '965.1', label: ' ESPECTACULOS EN SALAS Y LOCALES' },
  { value: '965.2', label: ' ESPECTACULOS AL AIRE LIBRE' },
  { value: '965.3', label: ' ESPECTACULOS FUERA ESTABLECIMIENTO' },
  { value: '965.4', label: ' EMPRESAS DE ESPECTACULOS' },
  { value: '965.5', label: ' ESPECTACULOS TAURINOS' },
  { value: '966', label: 'BIBLIOT.,ARCHIV.,MUSEOS,JARDINES Y ZOO' },
  { value: '966.1', label: ' BIBLIOTECAS Y MUSEOS' },
  { value: '966.2', label: ' ZOOLOGICOS, BOTANICOS Y SIMILARES' },
  { value: '966.9', label: ' OTROS SERVICIOS CULTURALES NCOP' },
  { value: '967', label: 'INSTAL. DEPORT. Y ESCUELAS Y PERFEC.DEP.' },
  { value: '967.1', label: ' INSTALACIONES DEPORTIVAS' },
  { value: '967.2', label: ' ESCUELAS Y SERV. PERFECC. DEL DEPORTE' },
  { value: '967.3', label: ' ALQUILER ARTICULOS DEPORTE EN INST. DEP.' },
  { value: '968', label: 'ESPECTACULOS DEPORTIVOS' },
  { value: '968.1', label: ' INSTALAC. CELEB. ESPECTACULOS DEPORTIVOS' },
  { value: '968.2', label: ' ORGANIZACION ESPECTACULOS DEPORTIVOS' },
  { value: '968.3', label: ' ORGANIZ.ESPECT.DEPORT. FEDERAC. Y CLUBES' },
  { value: '969', label: 'OTROS SERVICIOS RECREATIVOS N.C.O.P.' },
  { value: '969.1', label: ' SALAS DE BAILE Y DISCOTECAS' },
  { value: '969.2', label: ' CASINOS DE JUEGO' },
  { value: '969.3', label: ' JUEGOS DE BINGO' },
  { value: '969.4', label: ' MAQUINAS RECREATIVAS Y DE AZAR' },
  { value: '969.5', label: ' JUEGOS BILLAR, PING-PONG, BOLOS Y OTROS' },
  { value: '969.6', label: ' SALONES RECREATIVOS Y DE JUEGO' },
  { value: '969.7', label: ' OTRAS MAQUINAS AUTOMATICAS' },
  { value: '971', label: 'LAVANDERIAS Y TINTORERIAS' },
  { value: '971.1', label: ' TINTE,LIMP. SECO, LAVADO Y PLANCHADO' },
  { value: '971.2', label: ' LIMPIEZA Y TEÑIDO DEL CALZADO' },
  { value: '971.3', label: ' ZURCIDO Y REPARACION DE ROPAS' },
  { value: '972', label: 'PELUQUERIAS Y SALONES DE BELLEZA' },
  { value: '972.1', label: ' SERV. PELUQUERIA SEÑORAS Y CABALLEROS' },
  { value: '972.2', label: ' SALONES E INSTITUTOS DE BELLEZA' },
  { value: '973', label: 'SERVICIOS FOTOGRAFICOS Y FOTOCOPIAS' },
  { value: '973.1', label: 'SERVICIOS FOTOGRAFICOS' },
  { value: '973.2', label: 'MAQUINAS AUTOMAT. FOTOGRAFIAS Y FOTOCOP.' },
  { value: '973.3', label: 'SERV. COPIAS DOCUMENTOS MAQ.FOTOCOPIA' },
  { value: '974', label: 'AGENCIAS PRESTACION SERV. DOMESTICOS' },
  { value: '975', label: 'SERVICIOS DE ENMARCACION' },
  { value: '979', label: 'OTROS SERVICIOS PERSONALES N.C.O.P.' },
  { value: '979.1', label: 'SERV. POMPAS FUNEBRES' },
  { value: '979.2', label: 'ADORNO DE TEMPLOS Y OTROS LOCALES' },
  { value: '979.3', label: 'AGENCIAS MATRIMONIALES Y OTROS SERVICIOS' },
  { value: '979.4', label: 'ADIESTRAMIENTO Y CUIDADOS DE ANIMALES' },
  { value: '979.9', label: 'OTROS SERVICIOS PERSONALES NCOP' },
  { value: '981', label: 'JARD., PARQ.ATRACC. Y ACUAT. Y PIST.PAT.' },
  { value: '981.1', label: 'CURIOSIDADES EN PARQUES, GRUTAS, ...' },
  { value: '981.2', label: 'JARDINES DE RECREO' },
  { value: '981.3', label: 'PARQUES DE ATRACCIONES (TAMB. ACUATICOS)' },
  { value: '982', label: 'TOMBOL., VERB., LOTERIAS, APUEST.DEPORT.' },
  { value: '982.1', label: 'TOMBOLAS Y RIFAS AUTORIZADAS ESTABLECIM.' },
  { value: '982.2', label: 'TOMBOLAS Y RIFAS AUTOR. SIN ESTABLECIM.' },
  { value: '982.3', label: 'EXPOSICION FIGURAS DE CERA ESTABLECIM.' },
  { value: '982.4', label: 'OTRAS ATRACC.,SERVICIOS, FUERA ESTABLEC.' },
  { value: '982.5', label: 'ORGANIZ.Y CELEB.APUESTAS DEPORT.LOTERIA' },
  { value: '983', label: 'AGENCIAS DE COLOCACION DE ARTISTAS' },
  { value: '989', label: 'OTRAS ACTIV. DEL ESPECTACULO Y TURISMO' },
  { value: '989.1', label: 'EXPEDICION BILLETES ESPECTACULOS' },
  { value: '989.2', label: 'SERV. ORGANIZ. CONGRESOS,ASAMBLEAS, ...' },
  { value: '989.3', label: 'PARQUES O RECINTOS FERIALES' },
  { value: '991', label: 'PREST.SERV.SOCIED.DESARROLLO INDUS. REG.' },
  { value: '999', label: 'OTROS SERVICIOS N.C.O.P.' },
];
